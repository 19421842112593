import React, { Fragment } from 'react';
import Footer from '../../components/footer';
import Scrollbar from '../../components/scrollbar';
import Blog from '../../components/Blog';
import NoticesForAllPage from '../../components/NoticesForAllPage';
import RightSideInfo from '../../components/RightSideInfo';

const BlogPage = () => {
  return (
    <Fragment>
      <NoticesForAllPage />

      <section>
        <div className=' row  '>
          <div className='col-md-8  container'>
            <Blog />
          </div>
          <div className='col-md-4'>
            <RightSideInfo />
          </div>
        </div>
      </section>

      <Footer />
      <Scrollbar />
    </Fragment>
  );
};
export default BlogPage;
