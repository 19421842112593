import { Interweave } from "interweave";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {
  BASE_URL,
  GET_MANAGEMENT_TEAM,
  GET_OWNER_BY_RL,
  GET_RL_WEBSITE_CONTENTS_BY_MENU_ID,
} from "../../constant/constants";

function items(obj) {
  let content = [];

  // eslint-disable-next-line no-unused-vars
  for (let key in obj) {
    let contentItem = {};
    let objs = obj;

    contentItem[`${key}`] = objs[key];
    content.push(contentItem);
  }
  return content;
}
const Team = (props) => {
  const [contents, setContents] = useState("");
  const [members, setMember] = useState({});
  const [teams, setTeams] = useState([]);
  useEffect(() => {
    fetch(`${GET_OWNER_BY_RL}?key=${props?.rl_no}`)
      .then((response) => response.json())
      .then((searchedMemberData) => {
        setMember(searchedMemberData?.agencies[0]);
      });

    fetch(`${GET_MANAGEMENT_TEAM}?key=${props?.rl_no}`)
      .then((response) => response.json())
      .then((searchTeams) => {
        setTeams(searchTeams?.teams);
      });
  }, [props.rl_no]);
  const { id } = useParams();
  console.log("projecID", id);

  //for content
  useEffect(() => {
    fetch(`${GET_RL_WEBSITE_CONTENTS_BY_MENU_ID}/${props.id}/${props.rl_no}`)
      .then((response) => response.json())
      .then((data) => {
        setContents(data.menu_contents);

        console.log("allmenucontent", data.menu_contents);
      })
      .catch(() => {});
  }, [id, props.id, props.rl_no]);

  //for content items
  const newContents = items(contents);

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <section className='wpo-features-section-s6 section-padding bg-white py-5'>
      <div className='container'>
        <h1 className="text-center mb-0 mt-2"><b>Our Management Team</b></h1>
        <div className='row justify-content-center'>
          <div className='col-lg-6'>
            <div className='wpo-section-title'>
              {newContents.map((service, index) =>
                Object.entries(service).map(([key, value]) =>
                  key === "Management Team" ? (
                    <div className='' key={index}>
                      <Interweave
                        allowAttributes
                        allowElements
                        disableLineBreaks={true}
                        content={value}
                      />
                    </div>
                  ) : (
                    " "
                  )
                )
              )}
            </div>
          </div>
        </div>

        <div
          className='container pe-0 table-responsive px-1'
          style={{ fontSize: windowWidth < 767 && "10px", padding: windowWidth < 767 && "5px"  }}>
          <table className='table table-hover table-responsive rounded'>
            <thead>
              <tr className='bg-secondary text-white'>
                <th scope='col' style={{ textAlign: "center" }}>
                  SL
                </th>
                <th scope='col' style={{ textAlign: "center" }}>
                  Photo
                </th>
                <th scope='col'>Name & Designation</th>
                <th scope='col'>Address, Phone, Email & Web</th>
              </tr>
            </thead>
            <tbody>
              {members?.items?.map((owner, index) => (
                <tr
                  style={{ backgroundColor: index % 2 === 0 && "#E4F8FF" }}
                  key={index}>
                  <th scope='row'>{index + 1}</th>
                  <td className='p-2 text-center'>
                    {owner?.image ? (
                      <img
                        src={`${BASE_URL}${owner?.image}`}
                        className='rounded'
                        style={{
                          width: windowWidth < 767 ? "70px" : "100px",
                          height: windowWidth < 767 ? "70px" : "100px",
                        }}
                        loading='lazy'
                        alt='...'
                      />
                    ) : (
                      <img
                        src='/user.png'
                        className='rounded'
                        style={{
                          width: windowWidth < 767 ? "70px" : "100px",
                          height: windowWidth < 767 ? "70px" : "100px",
                        }}
                        loading='lazy'
                        alt='...'
                      />
                    )}
                  </td>
                  <td className='p-2 '>
                    Name: {owner?.owner_name} <br />
                    {owner?.designation} in &nbsp;
                    {owner?.agency?.name}
                    <br />
                    RL No:
                    {owner?.agency?.rl_no}
                  </td>
                  <td className='p-2'>
                    {owner?.home_address} <br />
                    Phone: {owner?.telephone} <br />
                    E-mail: {owner?.email} <br />
                    Web: {owner?.website} <br />
                  </td>
                </tr>
              ))}

              {teams?.map((team, index) => (
                <tr
                  style={{
                    backgroundColor:
                      members?.items?.length + (index % 2) === 0 && "#E4F8FF",
                  }}
                  key={index}>
                  <th scope='row'>{members?.items?.length + index + 1}</th>
                  <td className='p-2 text-center'>
                    {team?.image ? (
                      <img
                        src={`${BASE_URL}${team?.image}`}
                        className='rounded'
                        width='100'
                        loading='lazy'
                        height='100'
                        alt='...'
                      />
                    ) : (
                      <img
                        src='/user.png'
                        className='rounded'
                        width='100'
                        loading='lazy'
                        height='100'
                        alt='...'
                      />
                    )}
                  </td>
                  <td className='p-2 '>
                    Name: {team?.name} <br />
                    {team?.designation} in &nbsp;
                    {team?.agency?.name}
                    <br />
                    RL No:
                    {team?.agency?.rl_no}
                  </td>
                  <td className='p-2'>
                    {team?.address} <br />
                    Phone: {team?.primary_phone} <br />
                    E-mail: {team?.email} <br />
                    Web: {team?.website} <br />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </section>
  );
};

export default Team;
