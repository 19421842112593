import React, { useEffect, useState } from "react";
import {
  BASE_URL,
  GET_RL_WEBSITE_CONTENTS_BY_MENU_ID,
  GET_RL_WEBSITE_IMAGE_BY_MENU_ID,
} from "../../constant/constants";
import shape from "../../images/ab-shape-2.png";
import { Interweave } from "interweave";

function items(obj) {
  let content = [];

  // eslint-disable-next-line no-unused-vars
  for (let key in obj) {
    let contentItem = {};
    let objs = obj;

    contentItem[`${key}`] = objs[key];
    content.push(contentItem);
  }
  return content;
}

const RLWebsiteAbout = (props) => {
  // const { id } = useParams();
  console.log("aboutID", props.id);
  const [contents, setContents] = useState("");
  const [contentsImage, setContentsImage] = useState("");

  //for content images
  useEffect(() => {
    fetch(`${GET_RL_WEBSITE_IMAGE_BY_MENU_ID}/${props.id}/${props.rl_no}`)
      .then((response) => response.json())
      .then((data) => {
        // setContentImages(data.content_images);
        setContentsImage(data.content_images);
        console.log("imagesContent", data.content_images);
        Object.entries(data.content_images).map(([key, value]) =>
          console.log("imageValue", key, value)
        );
      })
      .catch(() => {});
  }, [props.id, props.rl_no]);

  useEffect(() => {
    fetch(`${GET_RL_WEBSITE_CONTENTS_BY_MENU_ID}/${props.id}/${props.rl_no}`)
      .then((response) => response.json())
      .then((data) => {
        setContents(data.menu_contents);

        console.log("allmenucontent", data);
      })
      .catch(() => {});
  }, [props.id, props.rl_no]);
  console.log("contents", contents);

  const newContents = items(contents);
  console.log("content", newContents);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  return (
    <section className='wpo-about-section section-padding py-5 mt-5'>
      <div className='container'>
        <div className='row align-items-center'>
          <div className='col-lg-4 col-md-12 col-12'>
            <div className='wpo-about-wrap'>
              {Object.entries(contentsImage ? contentsImage : {}).map(
                ([key, value]) =>
                  key === "About" ? (
                    <div className='wpo-about-img'>
                      <img
                        style={{
                          height: windowWidth < 600 ? "350px" : "500px",
                        }}
                        src={`${BASE_URL}/media/${value}`}
                        alt=''
                      />
                      <div className='wpo-ab-shape-1'>
                        <div className='s-s1'></div>
                        <div className='s-s2'></div>
                      </div>
                      <div className='wpo-ab-shape-2'>
                        <img src={shape} alt='' />
                      </div>
                    </div>
                  ) : (
                    " "
                  )
              )}
            </div>
          </div>
          <div className='col-lg-8 col-md-12 col-12 pl-2'>
            <div className='text-justify'>
              {newContents.map((service, index) =>
                Object.entries(service).map(([key, value]) =>
                  key === "About" ? (
                    <div className='' key={index}>
                      <Interweave
                        allowAttributes
                        allowElements
                        disableLineBreaks={true}
                        content={value}
                        className='px-3 text-justify'
                      />
                    </div>
                  ) : (
                    " "
                  )
                )
              )}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default RLWebsiteAbout;
