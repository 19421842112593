/* eslint-disable no-unused-vars */
/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import MobileMenu from '../../components/MobileMenu';
import { ADMIN_SITE_LINK, BASE_URL } from '../../constant/constants';

const Header2 = (props) => {
  const [isSearchShow, setIsSearchShow] = useState(false);
  const [time, setTime] = useState(getCurrentTime());

  useEffect(() => {
    const intervalID = setInterval(() => {
      setTime(getCurrentTime());
    }, 1000);

    return () => clearInterval(intervalID);
  }, []);

  function getCurrentTime() {
    const now = new Date();
    let hours = now.getHours();
    const period = hours >= 12 ? 'PM' : 'AM';
    hours = hours % 12 || 12;
    const minutes = now.getMinutes().toString().padStart(2, '0');
    const seconds = now.getSeconds().toString().padStart(2, '0');
    return `${hours}:${minutes}:${seconds} ${period}`;
  }

  const clickHandler = () => {
    window.scrollTo(10, 0);
  };

  const jwtAccessToken = localStorage.getItem('jwt_access_token');
  const userImage = localStorage.getItem('user_image');
  const isSmallScreen = window.innerWidth <= 576; // Adjust the breakpoint as needed

  const dynamicHeight = isSmallScreen ? '70px' : '40px';

  return (
    <header id='header' className={`${props.topbarNone}`}>
      <div
        className={`wpo-site-header  ${props.hclass}`}
        style={{ backgroundColor: '#0067B4' }}>
        <div className={` row bg-white d-none d-md-flex mx-0 ${props.hclass}`}>
          <div className='col-md-10'>
            <img
              style={{
                marginLeft: '100px',
              }}
              src={`${BASE_URL}${props.Logo}`}
              loading='lazy'
              alt=''
            />
          </div>
          <div
            id='clock'
            className='col-md-2 mx-auto my-auto fs-6 fw-bold text-primary'>
            <div
              className='mx-auto'
              style={{ fontSize: '14px', justifyContent: 'space-around' }}>
              <a
                href={props?.Facebook}
                target='_blank'
                rel='noopener noreferrer'>
                <i
                  className='fa-brands fa-xl mx-1 rounded-circle fa-facebook '
                  style={{
                    color: '#1877F2',
                  }}></i>
              </a>
              <a href={props?.Twitter} target='_blank'>
                <i
                  className='fa-brands fa-xl mx-1 rounded-circle  fa-square-twitter'
                  style={{
                    color: '#1D9BF0',
                    padding: '2px',
                    borderRadius: '10px',
                  }}></i>
              </a>

              <a href={props?.Youtube} target='_blank'>
                <i
                  className='fa-brands fa-xl mx-1 rounded-circle  fa-youtube'
                  style={{ color: '#FF0000' }}></i>
              </a>
            </div>
            <div
              className='my-1'
              style={{
                fontSize: '17px',
                color: '#6C6C6C',
                lineHeight: '24.2857px',
                textDecoration: 'none solid rgb(108, 108, 108)',
              }}>
              {time}
            </div>
          </div>
        </div>
        <nav
          className='navigation navbar navbar-expand-lg navbar-light'
          style={{ height: dynamicHeight }}>
          <div className='container-fluid'>
            <div className='row align-items-center'>
              <div className='col-lg-3 col-md-3 col-3 d-lg-none dl-block'>
                <div className='mobail-menu'>
                  <MobileMenu menu={props.menu} />
                </div>
              </div>
              <div className='col-lg-3 col-md-6 col-6'>
                <div className='navbar-header'></div>
              </div>
              <div className='col-lg-7 col-md-1 col-1'>
                <div
                  id='navbar'
                  className='collapse navbar-collapse navigation-holder'>
                  <button className='menu-close'>
                    <i className='ti-close'></i>
                  </button>

                  {props?.menu?.map((n) => (
                    <ul className='nav navbar-nav mb-lg-0' key={n.id}>
                      <li className='menu-item-has-children'>
                        {n?.name === 'Home' ? (
                          <Link onClick={clickHandler} to={`/`}>
                            {n?.name === 'Home' ? n?.name : ''}
                          </Link>
                        ) : n?.children.length === 0 ? (
                          <Link
                            onClick={clickHandler}
                            to={`/${n.name.toLowerCase()}/${n.id}`}>
                            {n?.name}
                          </Link>
                        ) : (
                          <Link>
                            {n?.name}
                            <i
                              className='fa-solid fa-angle-down'
                              style={{
                                color: 'white',
                                marginLeft: '3px',
                              }}></i>
                          </Link>
                        )}

                        {n.children.length > 0 && (
                          <ul
                            className='sub-menu '
                            style={{
                              display: 'inline',
                              color: 'white',
                              whiteSpace: 'normal',
                              fontFamily: 'Roboto, sans-serif',
                              fontSize: '14px',
                              fontWeight: '300',
                              lineHeight: '20px',
                              wordSpacing: '0px',
                            }}>
                            {n.children.map((child) => (
                              <li key={child.id}>
                                <Link
                                  onClick={clickHandler}
                                  to={`/${child.name.toLowerCase()}/${
                                    child.id
                                  }`}>
                                  {child.name}
                                </Link>
                              </li>
                            ))}
                          </ul>
                        )}
                      </li>
                    </ul>
                  ))}

                  <ul className='nav navbar-nav mb-1 mb-lg-0 mx-3'>
                    <li className='menu-item-has-children'>
                      {jwtAccessToken ? (
                        <img
                          src={userImage}
                          alt=''
                          width='30'
                          loading='lazy'
                          height='30'
                          className='rounded-circle'
                        />
                      ) : (
                        <a href={ADMIN_SITE_LINK} target='_blank'>
                          <img
                            src='/user.png'
                            alt=''
                            width='30'
                            height='30'
                            loading='lazy'
                            className='rounded-circle'
                          />
                        </a>
                      )}
                      <ul
                        className='sub-menu'
                        style={{ display: jwtAccessToken ? 'block' : 'none' }}>
                        <li>
                          <Link onClick={clickHandler} to='/profile'>
                            Profile
                          </Link>
                        </li>
                        <li>
                          <Link
                            onClick={() => {
                              localStorage.removeItem('jwt_access_token');
                              localStorage.removeItem('user_id');
                              // ... remove other items
                            }}
                            to='/'>
                            Logout
                          </Link>
                        </li>
                      </ul>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </nav>
      </div>
    </header>
  );
};

export default Header2;
