import React, { useEffect, useState } from 'react';
import Marquee from 'react-fast-marquee';
import { Link } from 'react-router-dom';

import { GET_NOTICES_WITHOUT_PAGINATION } from '../../constant/constants';

const NoticesForAllPage = (props) => {
  const ClickHandler = () => {
    window.scrollTo(10, 0);
  };

  const [notices, setNotices] = useState([]);
  useEffect(() => {
    fetch(`${GET_NOTICES_WITHOUT_PAGINATION}`)
      .then((response) => response.json())
      .then((data) => {
        setNotices(data.notices);
      })
      .catch(() => {});
  }, []);
  return (
    <section
      className=' justify-center mx-5 rounded  text-white text-center d-flex mx-auto  px-2'
      style={{
        fontSize: '14px',
        padding: '2px',
        marginTop: '30px',
        marginBottom: '20px',
        whiteSpace: 'nowrap',
        width: '90%',
        backgroundColor: '#0E89CB',

        borderBottom: '2px solid green',
      }}>
      <span>NOTICES & EVENTS:</span>
      <Marquee
        pauseOnHover='true'
        style={{ cursor: 'pointer' }}
        className='ms-4'>
        {notices?.map((notice, index) => (
          <Link
            key={index}
            to={`/pdfShow/notice/${notice?.id}`}
            onClick={ClickHandler}
            className='text-white'>
            &nbsp;&nbsp;&nbsp;
            <i className='fa-solid fa-pen-to-square'></i>&nbsp;{notice?.title}
          </Link>
        ))}
      </Marquee>
    </section>
  );
};

export default NoticesForAllPage;
