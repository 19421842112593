import React, { Fragment, useEffect, useState } from "react";
import Contactpage from "../../components/Contactpage";
import Footer from "../../components/footer";
import Scrollbar from "../../components/scrollbar";
import { GET_MENUS_ALL_NESTED } from "../../constant/constants";
import RightSideInfo from "../../components/RightSideInfo";
import NoticesForAllPage from "../../components/NoticesForAllPage";
import Mappage from "../../components/Mappage";

const ContactPage = () => {
  const [homeId, setHomeId] = useState();

  useEffect(() => {
    fetch(`${GET_MENUS_ALL_NESTED}`)
      .then((response) => response.json())
      .then((data) => {
        data.menus.find((e) => (e.name === "Home" ? setHomeId(e.id) : null));
      })
      .catch(() => {});
  }, []);

  return (
    <Fragment>
      <NoticesForAllPage />

      <section>
        <div className=' row  '>
          <div className='col-md-8  container'>
            <Contactpage />
          </div>
          <div className='col-md-4'>
            <RightSideInfo />
          </div>
        </div>
      </section>
      <Mappage id={homeId} tClass={"wpo-testimonial-area-s3 "} />

      <Footer />
      <Scrollbar />
    </Fragment>
  );
};
export default ContactPage;
