/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import 'react-toastify/dist/ReactToastify.css';
import { useRef } from 'react';
import Swal from 'sweetalert2';

import {
  CREATE_RL_WEBSITE_COMPLAIN,
  GET_COUNTRIES_WITHOUT_PAGINATION,
  MEMBER_WITHOUT_PG,
} from '../../constant/constants';
import { useDispatch } from 'react-redux';

const RlWebsiteComplain = (props) => {
  const dispatch = useDispatch();
  const [data, setData] = useState({});
  // eslint-disable-next-line no-unused-vars
  const [members, setMembers] = useState([]);
  const [countrys, setCountrys] = useState([]);
  const [selectedFile, setSelectedFile] = useState(null);
  const formRef = useRef(null);

  useEffect(() => {
    fetch(`${GET_COUNTRIES_WITHOUT_PAGINATION}`)
      .then((response) => response.json())
      .then((data) => {
        setCountrys(data?.countries);
      })
      .catch(() => {});
    fetch(`${MEMBER_WITHOUT_PG}`)
      .then((response) => response.json())
      .then((data) => {
        setMembers(data?.agencies);
      })
      .catch(() => {});
  }, [data?.session]);

  const updateData = (e) => {
    setData({
      ...data,
      [e.target.name]: e.target.value,
    });
  };

  const handleClick = (event) => {
    event.preventDefault();
    if (!data?.name || !data?.complain) {
      Swal.fire({
        position: 'top-center',
        icon: 'warning',
        title: 'Name & Complain is mandatory',
        showConfirmButton: false,
        timer: 1000,
      });
    } else {
      // Proceed with your normal ClickHandler logic
      ClickHandler();
    }
  };

  // const ClickHandler = (event) => {
  //   event.preventDefault();
  //   const formData = new FormData();
  //   formData.append('file', selectedFile);

  //   const complain = {
  //     agency: props?.memberId,
  //     name: data?.name,
  //     phone: data?.phone ? data?.phone : '',
  //     email: data?.email ? data?.email : '',
  //     passport: data?.passport ? data?.passport : '',
  //     country: data?.country ? data?.country : '',
  //     complain: data?.complain ? data?.complain : '',
  //     file: selectedFile,
  //   };

  //   dispatch(saveRLWebsiteComplain(complain)).then((res) => {
  //     if (res.payload?.data?.id) {
  //       Swal.fire({
  //         position: 'top-center',
  //         icon: 'success',
  //         title: 'Your Complain has been send',
  //         showConfirmButton: false,
  //         timer: 1500,
  //       });
  //       formRef.current.reset();
  //       setSelectedFile(null);
  //     }
  //   });
  //   window.scrollTo(10, 0);
  // };

  const ClickHandler = async (event) => {
    debugger
 // Prevent default form submission behavior

    const formData = new FormData();
    formData.append('file', selectedFile || '');
    formData.append('agency', props?.memberId);
    formData.append('name', data?.name);
    formData.append('phone', data?.phone || '');
    formData.append('email', data?.email || '');
    formData.append('passport', data?.passport || '');
    formData.append('country', data?.country || '');
    formData.append('complain', data?.complain || '');

    try {
      const response = await fetch(CREATE_RL_WEBSITE_COMPLAIN, {
        method: 'POST',
        body: formData,
        headers: {
          // Authorization: `Bearer ${localStorage.getItem('jwt_access_token')}`,
        },
      });

      if (response.ok) {
        const responseData = await response.json();
        if (responseData?.id) {
          Swal.fire({
            position: 'top-center',
            icon: 'success',
            title: 'Your Complain has been sent',
            showConfirmButton: false,
            timer: 1500,
          });
          formRef.current.reset();
          setSelectedFile(null);
          window.scrollTo(10, 0);
        }
      } else {
        // Handle non-OK response (e.g., error handling)
        console.error('Error:', response.statusText);
      }
    } catch (error) {
      // Handle fetch errors
      console.error('Fetch error:', error);
    }
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    setSelectedFile(file);
  };

  return (
    <>
      <section className='wpo-contact-pg-section section-padding py-5'>
        <div className='container'>
          <div className='row'>
            <div className='col col-lg-10 offset-lg-1'>
              <div className='wpo-contact-title'>
                <h2>Have Any Problem?</h2>
                <p>
                  It is crucial to minimize any potential distractions on the
                  page when submitting a complaint to ensure that the reader can
                  focus on the complaint content
                </p>
              </div>
              <div className='wpo-contact-form-area mb-0'>
                <form className='form' ref={formRef}>
                  <div className='row justify-content-center'>
                    <div className='col-md-6 col-12 mb-4'>
                      <div className='form-field'>
                        <input
                          name='name'
                          onChange={updateData}
                          type='text'
                          id='name'
                          placeholder='Name '
                        />
                      </div>
                    </div>

                    <div className='col-md-6 col-12 mb-4 '>
                      <div className='form-field'>
                        <input
                          onChange={updateData}
                          id='passport'
                          name='passport'
                          placeholder='Passport'
                        />
                      </div>
                    </div>

                    <div className='col-md-6 col-12 mb-4'>
                      <div className='form-field'>
                        <input
                          name='email'
                          onChange={updateData}
                          type='text'
                          id='email'
                          placeholder='Email '
                        />
                      </div>
                    </div>

                    <div className='col-md-6 col-12 mb-4'>
                      <div className='form-field'>
                        <input
                          name='phone'
                          onChange={updateData}
                          type='text'
                          id='phone'
                          placeholder='Phone '
                        />
                      </div>
                    </div>
                    <div className='col-md-12 col-12 mb-4'>
                      <div className='form-field'>
                        <select
                          className='form-control'
                          onChange={updateData}
                          type='text'
                          name='country'>
                          <option value=''>Select Country From</option>
                          {countrys?.map((country, index) => (
                            <option key={index} value={country?.id}>
                              {country?.name}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                    <div className='col-lg-12'>
                      <div className='form-field'>
                        <textarea
                          id='complain'
                          onChange={updateData}
                          name='complain'
                          placeholder='Complain'></textarea>
                      </div>
                    </div>

                    <div className='container justify-content-center text-center mt-1'>
                      <div className='upload-section'>
                        <input type='file' onChange={handleFileChange} />
                      </div>
                      <div className='pdf-preview-section'>
                        {selectedFile && (
                          <embed
                            src={URL.createObjectURL(selectedFile)}
                            type='application/pdf'
                            width='50%'
                            height='300px'
                          />
                        )}
                      </div>
                    </div>
                    <div className='col-lg-12'>
                      <div className='form-submit'>
                        <button
                          onClick={handleClick}
                          // disabled={!data?.name || !data?.complain}
                          className='theme-btn'>
                          Send Message
                        </button>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default RlWebsiteComplain;
