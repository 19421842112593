import React, { Component } from "react";
import { Collapse, CardBody, Card } from "reactstrap";
import { Link } from "react-router-dom";
import "./style.css";

export default class MobileMenu extends Component {
  state = {
    isMenuShow: false,
    isOpen: 0,
  };

  menuHandler = () => {
    this.setState({
      isMenuShow: !this.state.isMenuShow,
    });
  };

  setIsOpen = (id) => () => {
    this.setState({
      isOpen: id === this.state.isOpen ? 0 : id,
    });
  };
  render() {
    const { isMenuShow, isOpen } = this.state;

    const jwt_access_token = localStorage.getItem("jwt_access_token");
    const user_image = localStorage.getItem("user_image");
    return (
      <div>
        <div className={`mobileMenu ${isMenuShow ? "show" : ""}`}>
          <ul className='nav navbar-nav mb-1 mb-lg-0 mx-3 responsivemenu'>
            <li className='menu-item-has-children'>
              {jwt_access_token ? (
                <img
                  src={user_image}
                  alt=''
                  width='60'
                  height='60'
                  loading='lazy'
                  className='rounded-circle ml-5'
                />
              ) : (
                <Link onClick={this.menuHandler} to='/login'>
                  <img
                    src='/user.png'
                    alt=''
                    width='40'
                    loading='lazy'
                    height='40'
                    className='rounded-circle justify-content-center'
                  />
                </Link>
              )}
              <ul
                className='sub-menu'
                style={{
                  display: jwt_access_token ? "block" : "none",
                }}
              >
                <li>
                  <Link onClick={this.menuHandler} to='/profile'>
                    Profile
                  </Link>
                </li>
                <li>
                  <Link
                    onClick={() => {
                      localStorage.removeItem("jwt_access_token");
                      localStorage.removeItem("user_id");
                      localStorage.removeItem("user_email");
                      localStorage.removeItem("user_name");
                      localStorage.removeItem("user_role");
                      localStorage.removeItem("first_name");
                      localStorage.removeItem("last_name");
                      localStorage.removeItem("user_image");
                      localStorage.removeItem("street_address_one");
                      localStorage.removeItem("primary_phone");
                    }}
                    to='/'
                  >
                    Logout
                  </Link>
                </li>
              </ul>
            </li>
          </ul>
          <ul className='responsivemenu'>
            {this.props.menu.map((item) => {
              return (
                <li key={item.id}>
                  {item.children.length !== 0 ? (
                    <p onClick={this.setIsOpen(item.id)}>
                      {item.name}
                      {item.children.length !== 0 ? (
                        <i className='fa fa-angle-right' aria-hidden='true'></i>
                      ) : (
                        ""
                      )}
                    </p>
                  ) : (
                    <Link
                      onClick={this.menuHandler}
                      to={
                        item.name === "Home"
                          ? "/"
                          : `/${item.name.toLowerCase()}/${item.id}`
                      }
                    >
                      {item.name}
                    </Link>
                  )}
                  {item.children ? (
                    <Collapse isOpen={item.id === isOpen}>
                      <Card>
                        <CardBody>
                          <ul>
                            {item.children.map((children) => (
                              <li key={children.id}>
                                <Link
                                  onClick={this.menuHandler}
                                  className='active'
                                  to={`/${children.name.toLowerCase()}/${
                                    children.id
                                  }`}
                                >
                                  {children.name}
                                </Link>
                              </li>
                            ))}
                          </ul>
                        </CardBody>
                      </Card>
                    </Collapse>
                  ) : (
                    ""
                  )}
                </li>
              );
            })}
          </ul>
        </div>

        <div className='showmenu' onClick={this.menuHandler}>
          <button type='button' className='navbar-toggler open-btn'>
            <span className='icon-bar first-angle'></span>
            <span className='icon-bar middle-angle'></span>
            <span className='icon-bar last-angle'></span>
          </button>
        </div>
      </div>
    );
  }
}
