import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./main-component/App/App";
import * as serviceWorker from "./serviceWorker";
import "./css/font-awesome.min.css";
import "./css/themify-icons.css";
import "./css/flaticon.css";

ReactDOM.render(<App />, document.getElementById("root"));

serviceWorker.unregister();
