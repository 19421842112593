import React, { Fragment, useEffect } from "react";
import Footer from "../../components/footer";
import Scrollbar from "../../components/scrollbar";
import RightSideInfo from "../../components/RightSideInfo";
import NoticesForAllPage from "../../components/NoticesForAllPage";
import MemberDetails from "../../components/MemberDetails";

const MemberDetailsPage = () => {
  useEffect(() => {
    window.scrollTo(10, 0);
  }, []);
  return (
    <Fragment>
      <NoticesForAllPage />

      <section>
        <div className=' row  '>
          <div className='col-md-8  container'>
            <MemberDetails />
          </div>
          <div className='col-md-4'>
            <RightSideInfo />
          </div>
        </div>
      </section>

      <Footer />
      <Scrollbar />
    </Fragment>
  );
};
export default MemberDetailsPage;
