import React, { useEffect, useState } from 'react';
import {
  BASE_URL,
  GET_RL_WEBSITE_CONTENTS_BY_MENU_ID,
  GET_RL_WEBSITE_NOTICE,
} from '../../constant/constants';
import { Interweave } from 'interweave';

function items(obj) {
  let content = [];

  // eslint-disable-next-line no-unused-vars
  for (let key in obj) {
    let contentItem = {};
    let objs = obj;

    contentItem[`${key}`] = objs[key];
    content.push(contentItem);
  }
  return content;
}

const RLWebsiteNotice = (props) => {
  const [notices, setNotices] = useState([]);
  const [contents, setContents] = useState('');

  //for content
  useEffect(() => {
    fetch(`${GET_RL_WEBSITE_NOTICE}?key=${props?.rl_no}`)
      .then((response) => response.json())
      .then((data) => {
        setNotices(data.member_notices ? data.member_notices : []);
      })
      .catch(() => {});
  }, [props.rl_no]);
  //for content
  useEffect(() => {
    fetch(`${GET_RL_WEBSITE_CONTENTS_BY_MENU_ID}/${props.id}/${props.rl_no}`)
      .then((response) => response.json())
      .then((data) => {
        setContents(data.menu_contents);

        console.log('allmenucontent', data.menu_contents);
      })
      .catch(() => {});
  }, [props.id, props.rl_no]);

  //for content items
  const newContents = items(contents);
  return (
    <section className='wpo-project-section-s2 pb-0 section-padding py-5 bg-white'>
      <div className='container-fluid'>
        <div className='sortable-gallery'>
          <div className='row'>
            <div className='col-lg-12'>
              <div className='project-grids gallery-active'>
                <div className={`wpo-campaign-area section-padding py-0`}>
                  <div className='container'>
                    <div className='row justify-content-center'>
                      <div className='col-lg-6'>
                        <div
                          className='wpo-section-title h1'
                          style={{ color: 'black' }}>
                          BMET Notice
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className={`wpo-campaign-area section-padding py-0`}>
                  <div className='container'>
                    <div className='row justify-content-center'>
                      <div className='col-lg-6'>
                        <div className='wpo-section-title mb-0 pb-0'>
                          {newContents.map((service, index) =>
                            Object.entries(service).map(([key, value]) =>
                              key === 'BMET Notice' ? (
                                <div className='' key={index}>
                                  <Interweave
                                    allowAttributes
                                    allowElements
                                    disableLineBreaks={true}
                                    content={value}
                                  />
                                </div>
                              ) : (
                                ' '
                              )
                            )
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {notices?.length > 0 ? (
                  <div className='mx-auto px-1 table-responsive row col-md-9 mx-auto text-center'>
                    <table className='table table-hover table-responsive bg-white'>
                      <thead>
                        <tr className='bg-secondary text-white'>
                          <th scope='col' style={{ textAlign: 'center' }}>
                            SL
                          </th>
                          <th scope='col'>Title</th>
                          <th scope='col' style={{ textAlign: 'center' }}>
                            Date
                          </th>

                          <th scope='col' style={{ textAlign: 'center' }}>
                            Show
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {notices.map((notice, index) => (
                          <tr
                            key={index}
                            style={{
                              backgroundColor:
                                index % 2 === 0 && 'rgb(234 245 226)',
                            }}>
                            <th scope='row' style={{ textAlign: 'center' }}>
                              {index + 1}
                            </th>

                            <td className='p-2'>{notice?.title}</td>
                            <td className='p-2 text-center'>{notice?.date}</td>

                            <td className='p-2 text-center'>
                              <i
                                className='fa-regular fa-eye text-primary'
                                style={{ cursor: 'pointer' }}
                                onClick={() =>
                                  notice.file &&
                                  window.open(`${BASE_URL}${notice.file}`)
                                }></i>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                ) : (
                  <div>
                    <h5 className='text-center'>There are BMET Notice .</h5>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default RLWebsiteNotice;
