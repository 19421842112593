/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/jsx-no-target-blank */
// /* eslint-disable jsx-a11y/anchor-is-valid */
// /* eslint-disable react/jsx-no-target-blank */
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { BASE_URL } from "../../constant/constants";
import MobileMenuForRLWebsite from "../MobileMenuForRLWebsite";

const Header2ForMember = (props) => {

  console.log("props", props)

  const [time, setTime] = useState(getCurrentTime());

  useEffect(() => {
    const intervalID = setInterval(() => {
      setTime(getCurrentTime());
    }, 1000);

    return () => clearInterval(intervalID);
  }, []);

  function getCurrentTime() {
    const now = new Date();
    let hours = now.getHours();
    const period = hours >= 12 ? "PM" : "AM";

    // Convert hours to 12-hour format
    hours = hours % 12 || 12;

    const minutes = now.getMinutes().toString().padStart(2, "0");
    const seconds = now.getSeconds().toString().padStart(2, "0");
    return `${hours}:${minutes}:${seconds} ${period}`;
  }

  const ClickHandler = () => {
    window.scrollTo(10, 0);
  };
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  return (
    <header id='header' className={`${props.topbarNone}`}>
      <div className={`wpo-site-header bg-white p-0 ${props.hclass}`}>
        <div
          className={` row bg-white mx-0 ${props.hclass}`}
          style={{ backgroundColor: "#0067B4" }}>
          <div className='col-md-10 d-flex'>
            <div>
              <img
                style={{
                  marginLeft: windowWidth < 767 ? "2px" : "100px",
                }}
                width='70px'
                height='70px'
                className='py-1'
                src={`${BASE_URL}${props.Logo}`}
                loading='lazy'
                alt=''
              />
            </div>
            <div className=' my-auto'>
              {windowWidth < 767 ? (
                <h3
                  className='font-weight-bold'
                  style={{
                    marginLeft: windowWidth < 767 ? "5px" : "30px",
                  }}>
                  {props?.siteName}
                </h3>
              ) : (
                <h1
                  className='font-weight-bold'
                  style={{
                    marginLeft: windowWidth < 767 ? "5px" : "30px",
                  }}>
                  {props?.siteName}
                </h1>
              )}
            </div>
          </div>
          <div
            id='clock'
            style={{
              display: windowWidth < 767 ? "none" : "inline",
            }}
            className='col-md-2 mx-auto my-auto fs-6 fw-bold text-primary'>
            <div
              className='mx-auto'
              style={{ fontSize: "14px", justifyContent: "space-around" }}>
              <a href={props?.Facebook} target='_blank'>
                <i
                  className='fa-brands fa-xl mx-1 rounded-circle fa-facebook '
                  style={{
                    color: "#1877F2",
                  }}></i>
              </a>
              <a href={props?.Twitter} target='_blank'>
                <i
                  fa-xl
                  className='fa-brands fa-xl mx-1 rounded-circle  fa-square-twitter'
                  style={{
                    color: "#1D9BF0",
                    padding: "2px",
                    borderRadius: "10px",
                  }}></i>
              </a>

              <a href={props?.Youtube} target='_blank'>
                <i
                  className='fa-brands fa-xl mx-1 rounded-circle  fa-youtube'
                  style={{ color: "#FF0000" }}></i>
              </a>
            </div>
            <div
              className='my-1'
              style={{
                fontSize: "17px",
                color: "#6C6C6C",
                lineHeight: "24.2857px",
                textDecoration: "none solid rgb(108, 108, 108)",
              }}>
              {time}
            </div>
          </div>
        </div>
        <nav
          className='navigation navbar navbar-expand-lg navbar-light'
          style={{ height: "45px", backgroundColor: "#0067B4" }}>
          <div className='container-fluid'>
            <div className='row align-items-center' style={{marginTop:'-15px'}}>
              <div className='col-lg-3 col-md-3 col-3 d-lg-none dl-block'>
                <div className='mobail-menu'>
                  <MobileMenuForRLWebsite menu={props.menu} rlNo={props?.rl_no} memberPost={props?.memberPost} />
                </div>
              </div>
              <div className='col-lg-3 col-md-6 col-6'>
                <div className='navbar-header'></div>
              </div>
              <div className='col-lg-7 col-md-1 col-1'>
                <div
                  id='navbar'
                  className='collapse navbar-collapse navigation-holder'>
                  <button className='menu-close'>
                    <i className='ti-close'></i>
                  </button>

                  {props?.menu?.map((n) => (
                    <ul className='nav navbar-nav mb-lg-0' key={n.id}>
                      <li className='menu-item-has-children  px-1'>
                        <Link onClick={ClickHandler} to={n?.name === "Member Post" ? `${props?.rl_no}/${n?.name}` :props?.memberPost ? `/${props?.rl_no}` : `#${n?.name}`}>
                          {n?.name === "Team"
                            ? "Management Team"
                            : n?.name === "Profile"
                            ? "Company Profile"
                            : n?.name === "Notice"
                            ? "BMET Notice"  : n?.name === "Member Post"
                            ? "Post"
                            : n?.name === "Circular"
                            ? "Overseas Employment Circular"
                            : n?.name}
                        </Link>
                      </li>
                    </ul>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </nav>
      </div>
    </header>
  );
};

export default Header2ForMember;
