// import React, { useEffect, useState } from 'react';
// import { Carousel } from 'react-bootstrap-v5';
// import {
//   BASE_URL,
//   GET_RL_WEBISTE_HOMEPAGE_SLIDER_ALL,
// } from '../../constant/constants';

// const HeroForMember = (props) => {
//   const [contents, setContents] = useState([]);

//   console.log('condfsdsdtents', contents);

//   const [windowWidth, setWindowWidth] = useState(window.innerWidth);
//   useEffect(() => {
//     const handleResize = () => {
//       setWindowWidth(window.innerWidth);
//     };

//     window.addEventListener('resize', handleResize);

//     return () => {
//       window.removeEventListener('resize', handleResize);
//     };
//   }, []);
//   //for content
//   useEffect(() => {
//     if (props?.rl_no) {
//       fetch(`${GET_RL_WEBISTE_HOMEPAGE_SLIDER_ALL}${props?.rl_no}`)
//         .then((response) => response.json())
//         .then((data) => {
//           data?.length > 0 && setContents(data);
//         })
//         .catch(() => {});
//     }
//   }, [props.rl_no]);

//   return (
//     <Carousel fade>
//       {contents?.map((n, item) => (
//         <Carousel.Item
//           key={n?.id}
//           style={{
//             backgroundImage: `url(${BASE_URL}${n?.image})`,
//             backgroundSize: 'cover',
//             width: '100%',
//             backgroundPosition: 'center',
//             height: windowWidth < 600 ? '300px' : '100vh',
//           }}></Carousel.Item>
//       ))}
//     </Carousel>
//   );
// };

// export default HeroForMember;

import React, { useEffect, useState } from 'react';
import './hero.css';
import {
  BASE_URL,
  GET_RL_WEBISTE_HOMEPAGE_SLIDER_ALL,
} from '../../constant/constants';

const HeroForMember = (props) => {
  const [contents, setContents] = useState([]);
  const [currentSlideIndex, setCurrentSlideIndex] = useState(0);
 const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    if (props?.rl_no) {
      fetch(`${GET_RL_WEBISTE_HOMEPAGE_SLIDER_ALL}${props?.rl_no}`)
        .then((response) => response.json())
        .then((data) => {
          data?.length > 0 && setContents(data);
        })
        .catch(() => {});
    }
  }, [props.rl_no]);

  // Function to handle next slide
  const nextSlide = () => {
    setCurrentSlideIndex((prevIndex) => (prevIndex + 1) % contents.length);
  };

  // Function to handle previous slide
  const prevSlide = () => {
    setCurrentSlideIndex((prevIndex) =>
      prevIndex === 0 ? contents.length - 1 : prevIndex - 1
    );
  };

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  return (
    <div className='slider-container'>
      {contents.map((item, index) => (
        <div
          key={index}
          className={`slide ${index === currentSlideIndex ? 'active' : ''}`}>
          <img
            className='d-block w-100'
            height={600}

              height= {windowWidth < 600 ? 300 :600}
            loading='lazy'
            src={`${BASE_URL}${item.image}`}
            alt={`Slide ${index}`}
          />
        </div>
      ))}
      {/* Buttons for navigation */}
      <button className='prev' onClick={prevSlide}>
        <i class='fa-solid fa-arrow-left-long'></i>{' '}
      </button>
      <button className='next' onClick={nextSlide}>
        <i className='fa-solid fa-arrow-right'></i>
      </button>
    </div>
  );
};

export default HeroForMember;
