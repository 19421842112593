/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {
  BASE_URL,
  GET_CONTENTS_BY_MENU_ID,
  GET_IMAGE_BY_MENU_ID,
} from "../../constant/constants";
import { Interweave } from "interweave";

function items(obj) {
  let content = [];

  // eslint-disable-next-line no-unused-vars
  for (let key in obj) {
    let contentItem = {};
    let objs = obj;

    contentItem[`${key}`] = objs[key];
    content.push(contentItem);
  }
  return content;
}

const History = (props) => {
  const { id } = useParams();
  const [contents, setContents] = useState("");
  const [contentsImage, setContentsImage] = useState("");

  useEffect(() => {
    fetch(`${GET_CONTENTS_BY_MENU_ID}/${id}`)
      .then((response) => response.json())
      .then((data) => {
        setContents(data.menu_contents);
      })
      .catch(() => {});
    // Get Content Image
    fetch(`${GET_IMAGE_BY_MENU_ID}/${id}`)
      .then((response) => response.json())
      .then((data) => {
        setContentsImage(data.content_images);
      })
      .catch(() => {});
  }, [id, props?.id]);

  const newContents = items(contents);

  return (
    <section className='wpo-about-text' style={{ marginLeft: "45px" }}>
      <div className='container'>
        <div className='row align-items-center'>
          <div className='container-fluid my-2'>
            {Object.entries(contentsImage ? contentsImage : {}).map(
              ([key, value]) =>
                key === "History" ? (
                  <img
                    style={{
                      borderRadius: "2%",
                      display: "block",
                      maxHeight: "fit-content",
                      marginLeft: "auto",
                      marginRight: "auto",
                    }}
                    className='d-block w-100 mx-auto text-center rounded'
                    src={`${BASE_URL}/media/${value}`}
                    loading='lazy'
                    alt=''
                  />
                ) : (
                  " "
                )
            )}
          </div>
          <div className='col-lg-12 col-md-12 col-12'>
            <div className='wpo-about-text'>
              {newContents.map((service, index) =>
                Object.entries(service).map(([key, value]) =>
                  key === "History" ? (
                    <div className='' key={index}>
                      <Interweave
                        allowAttributes
                        allowElements
                        disableLineBreaks={true}
                        content={value}
                      />
                    </div>
                  ) : " " && key === "Vision" ? (
                    <div className='' key={index}>
                      <Interweave
                        allowAttributes
                        allowElements
                        disableLineBreaks={true}
                        content={value}
                      />
                    </div>
                  ) : (
                    " "
                  )
                )
              )}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default History;
