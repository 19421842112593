import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

import "react-toastify/dist/ReactToastify.css";

import { BASE_URL, GET_ALL_FORMS } from "../../constant/constants";
import Loading from "../Loading";

const Form = (props) => {
  const [forms, setForms] = useState([]);

  //for content
  useEffect(() => {
    fetch(`${GET_ALL_FORMS}`)
      .then((response) => response.json())
      .then((data) => {
        setForms(data.forms);
      })
      .catch(() => {});
  }, []);

  return (
    <>
      {forms?.length > 0 ? (
        <div className='container'>
          <div className='pt-4 mx-2 text-center'>
            <h3>All Forms</h3>
          </div>
          <table
            style={{ marginLeft: "40px", width: "95%" }}
            className='table table-hover table-responsive'
          >
            <thead>
              <tr className='bg-secondary text-white'>
                <th scope='col' style={{ textAlign: "center" }}>
                  SL
                </th>
                <th scope='col' style={{ textAlign: "center" }}>
                  Title
                </th>
                <th scope='col' style={{ textAlign: "center" }}>
                  Date
                </th>
                <th scope='col' style={{ textAlign: "center" }}>
                  View
                </th>
                <th scope='col' style={{ textAlign: "center" }}>
                  Donload
                </th>
              </tr>
            </thead>
            <tbody>
              {forms.map((form, index) => (
                <tr
                  key={index}
                  style={{ backgroundColor: index % 2 === 0 && "#E4F8FF" }}
                >
                  <th scope='row' className=' text-center'>
                    {index + 1}
                  </th>

                  <td className='p-2 text-center'>{form?.title}</td>
                  <td className='p-2 text-center'>{form?.date}</td>
                  <td className='p-2 text-center'>
                    <Link
                      to={`/pdfShow/form/${form.id}`}
                      style={{ cursor: "pointer" }}
                      // onClick={() => window.open(`${BASE_URL}${notice.file}`)}
                    >
                      <i className='fa-regular fa-eye  '></i>
                    </Link>
                  </td>
                  <td className='p-2 text-center'>
                    <i
                      className='fa-solid fa-download   text-primary'
                      style={{ cursor: "pointer" }}
                      onClick={() => window.open(`${BASE_URL}${form.file}`)}
                    ></i>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      ) : (
        <Loading />
      )}
    </>
  );
};

export default Form;
