import { Interweave } from "interweave";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { GET_RL_WEBSITE_CONTENTS_BY_MENU_ID } from "../../constant/constants";

function items(obj) {
  let content = [];

  // eslint-disable-next-line no-unused-vars
  for (let key in obj) {
    let contentItem = {};
    let objs = obj;

    contentItem[`${key}`] = objs[key];
    content.push(contentItem);
  }
  return content;
}
const RLWebsiteContactpage = (props) => {
  const { id } = useParams();
  const [contents, setContents] = useState("");

  console.log("menuID", id);

  //for get menu
  useEffect(() => {
    fetch(`${GET_RL_WEBSITE_CONTENTS_BY_MENU_ID}/${props.id}/${props.rl_no}`)
      .then((response) => response.json())
      .then((data) => {
        setContents(data.menu_contents);

        console.log("ContactContent", data);
      })
      .catch(() => {});
  }, [id, props.id, props.rl_no]);

  //for content items
  const newContents = items(contents);
  console.log("content Contact", newContents);

  return (
    <section
      className='wpo-contact-pg-section section-padding py-5'
      style={{ backgroundColor: "#E9FAFA" }}>
      <div className='container'>
        <div className={`wpo-campaign-area section-padding pb-0`}>
          <div className='container'>
            <div className='row justify-content-center pb-0 mb-0'>
              <div className='col-lg-6'>
                <div className='wpo-section-title pb-0 mb-0 py-0'>
                  {newContents.map((service, index) =>
                    Object.entries(service).map(([key, value]) =>
                      key === "Contact" ? (
                        <div className='' key={index}>
                          <Interweave
                            allowAttributes
                            allowElements
                            disableLineBreaks={true}
                            content={value}
                          />
                        </div>
                      ) : (
                        " "
                      )
                    )
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='row'>
          <div className='col col-lg-10 offset-lg-1'>
            <div className='office-info'>
              <div
                className='grid-container'
                style={{
                  display: "grid",
                  gridTemplateColumns: "repeat(3, 1fr)",
                  gap: "20px",
                }}></div>
              <div className='row ' style={{ justifyContent: "space-between" }}>
                <div className='col-md-4  mb-2'>
                  <div
                    className='office-info-item bg-white '
                    style={{ height: "100%", borderRadius: "20px" }}>
                    <div className='office-info-icon'>
                      <div className='icon'>
                        <i className='fi flaticon-placeholder'></i>
                      </div>
                    </div>
                    <div className='office-info-text'>
                      {newContents.map((service, index) =>
                        Object.entries(service).map(([key, value]) =>
                          key === "Address" ? (
                            <>
                              <Interweave
                                allowAttributes
                                allowElements
                                disableLineBreaks={true}
                                content={value}
                              />
                            </>
                          ) : (
                            " "
                          )
                        )
                      )}
                    </div>
                  </div>
                </div>
                <div className='col-md-4  mb-2'>
                  <div
                    className='office-info-item bg-white '
                    style={{ height: "100%", borderRadius: "20px" }}>
                    <div className='office-info-icon'>
                      <div className='icon'>
                        <i className='fi flaticon-email'></i>
                      </div>
                    </div>
                    <div className='office-info-text'>
                      {newContents.map((service, index) =>
                        Object.entries(service).map(([key, value]) =>
                          key === "Email Us" ? (
                            <>
                              <h2>{key}</h2>
                              <Interweave
                                allowAttributes
                                allowElements
                                disableLineBreaks={true}
                                content={value}
                              />
                            </>
                          ) : (
                            " "
                          )
                        )
                      )}
                    </div>
                  </div>
                </div>
                <div className='col-md-4  mb-2'>
                  <div
                    className='office-info-item bg-white'
                    style={{ height: "100%", borderRadius: "20px" }}>
                    <div className='office-info-icon'>
                      <div className='icon'>
                        <i className='fi flaticon-phone-call'></i>
                      </div>
                    </div>
                    <div className='office-info-text'>
                      {newContents.map((service, index) =>
                        Object.entries(service).map(([key, value]) =>
                          key === "Call Now" ? (
                            <>
                              <h2>{key}</h2>
                              <Interweave
                                allowAttributes
                                allowElements
                                disableLineBreaks={true}
                                content={value}
                              />
                            </>
                          ) : (
                            " "
                          )
                        )
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <section className='wpo-contact-map-section'>
        <div className='wpo-contact-map'>
          {newContents.map((service, index) =>
            Object.entries(service).map(([key, value]) =>
              key === "Map" ? (
                <>
                  <iframe title='Map' src={value.slice(3, -4)}></iframe>
                </>
              ) : (
                " "
              )
            )
          )}
        </div>
      </section>
    </section>
  );
};

export default RLWebsiteContactpage;
