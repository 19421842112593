import React, { Fragment } from "react";
import Footer from "../../components/footer";
import Scrollbar from "../../components/scrollbar";
import NoticesForAllPage from "../../components/NoticesForAllPage";
import ForeignEmbassy from "../../components/ForeignEmbassy";

const ForeignEmbassyPage = () => {
  return (
    <Fragment>
      <NoticesForAllPage />

      <section>
        <div className=' row  '>
          <div className='col-md-12  container'>
            <ForeignEmbassy />
          </div>
          {/* <div className='col-md-4'>
            <RightSideInfo />
          </div> */}
        </div>
      </section>

      <Footer />
      <Scrollbar />
    </Fragment>
  );
};
export default ForeignEmbassyPage;
