import React, { useEffect, useState } from "react";
import { MINISTRY_FILTER_WITHOUT_PG } from "../../constant/constants";
import Loading from "../Loading";

const MinistryList = (props) => {
  const [ministrys, setMinistrys] = useState([]);

  //for content
  useEffect(() => {
    fetch(`${MINISTRY_FILTER_WITHOUT_PG}`)
      .then((response) => response.json())
      .then((data) => {
        setMinistrys(data.ministry_lists);
      })
      .catch(() => {});
  }, []);
  return (
    <>
      {ministrys?.length > 0 ? (
        <div className='container'>
          <div className='pt-4 mx-2 text-center'>
            <h3>Ministry List</h3>
          </div>
          <table
            style={{ marginLeft: "40px", width: "95%" }}
            className='table table-hover table-responsive'
          >
            <thead>
              <tr className='bg-secondary text-white'>
                <th scope='col' style={{ textAlign: "center" }}>
                  SL
                </th>
                <th scope='col' style={{ textAlign: "center" }}>
                  Title
                </th>
              </tr>
            </thead>
            <tbody>
              {ministrys.map((ministry, index) => (
                <tr
                  key={index}
                  style={{ backgroundColor: index % 2 === 0 && "#E4F8FF" }}
                >
                  <th scope='row' className=' text-center'>
                    {index + 1}
                  </th>

                  <td className='p-2 text-center'>
                    <a
                      href={ministry?.link || "#"}
                      target='_blank'
                      rel='noopener noreferrer'
                    >
                      <span className='text-dark'>{ministry?.name}</span>
                    </a>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      ) : (
        <Loading />
      )}
    </>
  );
};

export default MinistryList;
