import React, { Fragment, useEffect, useState } from 'react';
import Footer from '../../components/footer';
import Scrollbar from '../../components/scrollbar';
import Blog from '../../components/Blog';
import NoticesForAllPage from '../../components/NoticesForAllPage';
import RightSideInfo from '../../components/RightSideInfo';
import { useParams } from 'react-router-dom';
import RLWebsiteNoticeBanner from '../../components/RLWebsiteNoticeBanner';
import Navbar2ForMember from '../../components/Navbar2ForMember';
import { GET_AGENCY_INFO } from '../../constant/constants';
import MemberBlog from '../../components/MemberBlog';

const MemberBlogPage = () => {
  const [scroll, setScroll] = React.useState(0);
    const [siteSetting, setSiteSetting] = useState({});
  const [rlNo, setRlNo] = useState();
  const [siteName, setSiteName] = useState('');
  const [homeId, setHomeId] = useState();
  const [serviceId, setServiceId] = useState();
  const [aboutId, setAboutId] = useState();
  const routeParams = useParams();



  const handleScroll = () => setScroll(document.documentElement.scrollTop);

  //for content
  useEffect(() => {
    fetch(`${GET_AGENCY_INFO}${routeParams?.memberRlNo}`)
      .then((response) => response.json())
      .then((data) => {
        setSiteName(data?.name);
      })
      .catch(() => {});
  }, [routeParams.memberRlNo]);
  return (
    <Fragment>
         <div>
          <Navbar2ForMember
            Logo={siteSetting?.logo}
            rl_no={routeParams?.memberRlNo}
          siteName={siteName}
          memberPost={true}
            Facebook={siteSetting?.facebook_url}
            Twitter={siteSetting?.twitter_url}
            Youtube={siteSetting?.instagram_url}
            hclass={'wpo-header-style-4 text-nowrap'}
            id={routeParams?.memberRlNo}
          />
        </div>
  <RLWebsiteNoticeBanner rl_no={routeParams?.memberRlNo} />
      <section>
        <div className=' row  '>
          <div className='col-md-12  container'>
            <MemberBlog rlNo={routeParams?.memberRlNo} />
          </div>
          
        </div>
      </section>

      <Footer />
      <Scrollbar />
    </Fragment>
  );
};
export default MemberBlogPage;
