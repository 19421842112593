import React, { useEffect, useState, useRef } from "react";
import { useParams } from "react-router-dom";
import { useReactToPrint } from "react-to-print";

import {
  BASE_URL,
  GET_MEMBER_BY_ID,
  GET_SITESETTINGS,
} from "../../constant/constants";

const MemberDetails = (props) => {
  const [member, setMember] = useState({});
  const [siteSetting, setSiteSetting] = useState({});
  const [inPrint, setInPrint] = useState(false);

  const { id } = useParams();

  //for content images
  useEffect(() => {
    fetch(`${GET_MEMBER_BY_ID}${id || props?.id}`)
      .then((response) => response.json())
      .then((data) => {
        setMember(data);
      })
      .catch(() => {});
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, props?.id]);

  useEffect(() => {
    fetch(`${GET_SITESETTINGS}`)
      .then((response) => response.json())
      .then((data) => {
        setSiteSetting(data.general_settings[0]);
      })
      .catch(() => {});
  }, []);

  //print dom ref
  const componentRef = useRef();

  //printer action
  const printAction = useReactToPrint({
    content: () => componentRef.current,
  });

  //print handler
  const handlePrint = () => {
    setInPrint(true);
    printAction();

    if (!inPrint) {
      if (member?.id) {
      } else {
        printAction();
        setInPrint(false);
      }
    }
  };

  return (
    <section>
      <div className='container' ref={componentRef} id='downloadPage'>
        <div className='row mt-5'>
          <div className='justify-content-center text-center mx-auto col-md-9'>
            <h6 className='text-nowrap' style={{ lineHeight: "12px" }}>
              <b> {siteSetting?.site_name}</b>
            </h6>
            <p className='text-nowrap' style={{ lineHeight: "12px" }}>
              {siteSetting?.address}
            </p>
            <p className='text-nowrap' style={{ lineHeight: "12px" }}>
              Mobile:{siteSetting?.phone}
            </p>
            <p className='text-nowrap' style={{ lineHeight: "12px" }}>
              E-Mail:{siteSetting?.email}
            </p>
            <p className='text-nowrap' style={{ lineHeight: "12px" }}>
              Web:{siteSetting?.site_address}
            </p>
          </div>
        </div>
        <h6 className='mx-auto text-primary text-center'>
          <b>Member Information</b>
        </h6>

        {member.items?.map(
          (item) =>
            item?.is_default && (
              <div
                className='border mx-3 p-3'
                style={{ fontFamily: "Roboto,sansSerif" }}>
                <table className='table d-flex table-borderless mx-auto justify-content-center '>
                  <thead>
                    <tr>
                      <td rowSpan={9} valign='top' className='px-4'>
                        {item?.image ? (
                          <img
                            style={{
                              width: "131px",
                              height: "173px",
                            }}
                            src={`${BASE_URL}${item?.image}`}
                            alt='owner'
                            loading='lazy'
                          />
                        ) : (
                          <img
                            style={{
                              width: "131px",
                              height: "173px",
                            }}
                            loading='lazy'
                            src='/user.png'
                            alt='owner'
                          />
                        )}
                      </td>
                      <th>Name of Agency </th>
                      <th>:{member?.name}</th>
                    </tr>
                    <tr>
                      <td className='text-nowrap'>Recruiting Licence No. </td>
                      <td>:{member?.rl_no}</td>
                    </tr>
                    <tr>
                      <td>Contact Person </td>
                      <td>:{item?.owner_name}</td>
                    </tr>
                    <tr>
                      <td>Designation</td>
                      <td>:{item?.designation}</td>
                    </tr>
                    <tr>
                      <td>Nationality</td>
                      <td>:Bangladeshi</td>
                    </tr>
                    <tr>
                      <td>Address</td>
                      <td>:{item?.home_address}</td>
                    </tr>
                    <tr>
                      <td>Telephone No. </td>
                      <td>:{item?.telephone}</td>
                    </tr>
                    <tr>
                      <td>Email</td>
                      <td>:{item?.email}</td>
                    </tr>
                    <tr>
                      <td>Web</td>
                      <td>
                        :&nbsp;
                        <a
                          href={`https://${member?.website}`}
                          target='_blank'
                          rel='noopener noreferrer'>
                          {member?.website === "nan" ? "" : member?.website}
                        </a>
                      </td>
                    </tr>
                  </thead>
                </table>
              </div>
            )
        )}
      </div>
      <div className='text-center'>
        <button
          onClick={() => handlePrint()}
          className='btn btn-danger mx-5 mt-2 text-center justify-content-center'>
          <i className='fa-solid fa-print 2xl px-4'></i>
        </button>
      </div>
    </section>
  );
};

export default MemberDetails;
