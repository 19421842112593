import React, { Fragment, useEffect, useState } from "react";
import Footer from "../../components/footer";
import Scrollbar from "../../components/scrollbar";
import { GET_MENUS_ALL_NESTED } from "../../constant/constants";
import Gallery from "../../components/Gallery";
import RightSideInfo from "../../components/RightSideInfo";
import NoticesForAllPage from "../../components/NoticesForAllPage";

const GalleryPage = () => {
  const [profileId, setProfileId] = useState(41);

  useEffect(() => {
    fetch(`${GET_MENUS_ALL_NESTED}`)
      .then((response) => response.json())
      .then((data) => {
        data.menus.find((e) =>
          e.name === "gallery" ? setProfileId(e.id) : null
        );
      })
      .catch(() => {});
  }, []);

  return (
    <Fragment>
      <NoticesForAllPage />

      <section>
        <div className=' row  '>
          <div className='col-md-8  container'>
            <Gallery id={profileId} />
          </div>
          <div className='col-md-4'>
            <RightSideInfo />
          </div>
        </div>
      </section>

      <Footer />
      <Scrollbar />
    </Fragment>
  );
};
export default GalleryPage;
