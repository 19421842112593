import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

import {
  MINISTRY_FILTER_WITHOUT_PG,
  GET_SITESETTINGS,
  GET_RESOURCES_WITHOUT_PAGINATION,
} from "../../constant/constants";

const Footer = (props) => {
  const [ministrys, setMinistrys] = useState([]);
  const [resources, setResources] = useState([]);

  const [siteSetting, setSiteSetting] = useState({});

  useEffect(() => {
    fetch(`${GET_SITESETTINGS}`)
      .then((response) => response.json())
      .then((data) => {
        setSiteSetting(data.general_settings[0]);
      })
      .catch(() => {});
    fetch(`${MINISTRY_FILTER_WITHOUT_PG}`)
      .then((response) => response.json())
      .then((data) => {
        setMinistrys(data?.ministry_lists);
      })
      .catch(() => {});
    fetch(`${GET_RESOURCES_WITHOUT_PAGINATION}`)
      .then((response) => response.json())
      .then((data) => {
        setResources(data?.resources);
      })
      .catch(() => {});
  }, []);

  const d = new Date();
  let year = d.getFullYear();
  const ClickHandler = () => {
    window.scrollTo(10, 0);
  };
  return (
    <footer
      className='wpo-site-footer mt-4'
      style={{ visibility: siteSetting?.id ? "visible" : "hidden" }}>
      <div className='wpo-upper-footer'>
        <div className='container'>
          <div className='row'>
            <div className='col col-lg-4 col-md-4 col-sm-12 col-12  d-flex'>
              <div className='widget about-widget text-white col-md-10  instagram'>
                <div className='widget-title'>
                  <h3>Contact Us</h3>
                </div>
                <div className=' d-flex'>
                  <div>
                    <i className='fa-solid fa-location-dot text-wrap   text-white mx-2'></i>
                  </div>
                  <div> {siteSetting?.address}</div>
                </div>
                <div className='my-3 d-flex'>
                  <div>
                    <i className='fa-solid fa-phone   text-white mx-2'></i>
                  </div>
                  <div> {siteSetting?.phone}</div>
                </div>
                <div className='my-3 d-flex'>
                  <div>
                    <i className='fa-solid fa-fax   text-white mx-2'></i>
                  </div>
                  <div> {siteSetting?.fax}</div>
                </div>
                <div className='my-3 d-flex'>
                  <div>
                    <i className='fa-solid fa-envelope   text-white mx-2'></i>
                  </div>
                  <div> {siteSetting?.email}</div>
                </div>
                <div className='my-3 d-flex'>
                  <div>
                    <i className='fa-solid fa-globe   text-white mx-2'></i>
                  </div>
                  <div> {siteSetting?.site_address}</div>
                </div>
              </div>
            </div>
            <div className='col col-lg-4 col-md-4 col-sm-12 col-12 justify-content-center d-flex'>
              <div className='widget instagram' style={{ marginLeft: "-80px" }}>
                <div className='widget-title'>
                  <h3>Resources</h3>
                </div>
                <ul
                  className=''
                  style={{ listStyle: "disc", flexDirection: "column" }}>
                  {resources?.map((resource, index) => (
                    <li key={index} className='text-nowrap text-white'>
                      {resource?.file ? (
                        <Link
                          to={`/pdfShow/resource/${resource.id}`}
                          style={{ cursor: "pointer" }}
                          onClick={ClickHandler}
                          // onClick={() => window.open(`${BASE_URL}${notice.file}`)}
                        >
                          <span className='text-white'>{resource?.title}</span>
                        </Link>
                      ) : (
                        <a
                          href={resource?.link || "#"}
                          target='_blank'
                          rel='noopener noreferrer'>
                          <span className='text-white'>{resource?.title}</span>
                        </a>
                      )}
                    </li>
                  ))}
                </ul>
              </div>
            </div>

            <div className='col col-lg-4 col-md-4 col-sm-12 col-12 justify-content-right d-flex '>
              <div className='widget instagram'>
                <div className='widget-title'>
                  <h3>Important Links</h3>
                </div>
                <ul style={{ listStyle: "disc", flexDirection: "column" }}>
                  {ministrys?.map((ministry, index) => (
                    <li key={index} className='text-nowrap text-white'>
                      <a
                        href={ministry?.link || "#"}
                        target='_blank'
                        rel='noopener noreferrer'>
                        <span className='text-white'>{ministry?.name}</span>
                      </a>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='wpo-lower-footer'>
        <div className='container'>
          <div className='row'>
            <div className='col col-md-8'>
              <small className='copyright text-white'>
                &copy; {year} Bangladesh Association of International Recruiting
                Agencies (BAIRA), All rights reserved.
              </small>
            </div>
            <div className='col col-md-4'>
              <small className='copyright text-white'>
                Developed By &nbsp;
                <a
                  href='http://bluebayit.com/'
                  target='_blank'
                  rel='noopener noreferrer'
                  className='text-warning'>
                  RAMS(Bluebay IT Limited)
                </a>
              </small>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
