/* eslint-disable no-unused-vars */
import React, { useState } from "react";
import { Link } from "react-router-dom";

const MemberSearchs = (props) => {
  const [position, setPosition] = useState(1);
  const [content, setContent] = useState("");
  const [key, setKey] = useState("0");
  const [type, setType] = useState("0");

  const ClickHandler = () => {
    window.scrollTo(10, 0);
  };

  const [data, setData] = useState({});

  const updateData = (e) => {
    setData({
      ...data,
      [e.target.name]: e.target.value,
    });
  };
  const alphabet = Array.from({ length: 26 }, (_, i) =>
    String.fromCharCode(65 + i)
  ); // Generate an array of letters A to Z

  const handleButtonClick = (letter) => {
    // Handle button click for a specific letter
  };
  return (
    <section
      style={{
        backgroundColor: "#EEE",
        borderRadius: "20px",
        borderBottom: "3px solid #0067B4",
        minHeight: "380px",
        maxHeight: "380px",
        marginLeft: "20px",
      }}
      className='wpo-features-item p-4 pb-0'>
      <form>
        <div className=' mx-2 text-center'>
          <h4>MEMBER SEARCH</h4>
        </div>
        <div className='wpo-donations-details p-0 mb-0'>
          <div className='row '>
            <div className='col-lg-12 col-md-12 col-sm-12 col-12 form-group'>
              <input
                type='text'
                className='form-control bg-white p-3 mb-2'
                name='name'
                onChange={updateData}
                id='name'
                placeholder=' Search by Member/Agency/RL No.'
                style={{ borderRadius: "20px" }}
              />
            </div>
          </div>
          <div className='row mb-2'>
            <div className='col-lg-12 col-md-12 col-sm-12 col-12 form-group'>
              <select
                className='form-control p-2'
                name='type'
                id='type'
                onChange={updateData}
                style={{ borderRadius: "20px" }}>
                <option value=''>Select Type</option>
                <option value='member'>Member</option>
                <option value='rl_no'>RL No.</option>
                <option value='agency'>Agency</option>
              </select>
            </div>
          </div>

          <button
            onClick={(event) => event.preventDefault()}
            className='btn btn-warning text-black my-1 justify-content-center'>
            <Link
              onClick={ClickHandler}
              to={`/member-search/${data?.name}/${
                data?.type ? data?.type : "rl_no"
              }/${0}`}>
              Search
            </Link>
          </button>
          <div className='col-md-12 mt-1'>
            <div>
              {alphabet?.map((letter, index) => (
                <button
                  key={index}
                  style={{
                    cursor: "pointer",
                    margin: "1px",
                    border: "1px solid gray",
                  }}
                  onClick={(event) => event.preventDefault()}>
                  <Link
                    // onClick={ClickHandler}
                    to={`/member-search/${key}/${type}/${letter}`}>
                    {letter}
                  </Link>
                </button>
              ))}
            </div>
          </div>
        </div>
      </form>
    </section>
  );
};

export default MemberSearchs;
