/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import {
  BASE_URL,
  GET_EXECUTIVE_MEMBER_BY_SESSIONS,
  GET_EXECUTIVE_MEMBER_SESSIONS,
} from "../../constant/constants";
import Loading from "../Loading";

const ExecutiveCommittee = (props) => {
  const { id } = useParams();
  const [executiveCommittees, setExecutiveCommittees] = useState([]);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [data, setData] = useState();

  const updateData = (e) => {
    setData({
      ...data,
      [e.target.name]: e.target.value,
    });
  };
  useEffect(() => {
    fetch(`${GET_EXECUTIVE_MEMBER_BY_SESSIONS}?key=${data?.session}`)
      .then((response) => response.json())
      .then((data) => {
        setExecutiveCommittees(data?.executive_members);
      })
      .catch(() => {});
  }, [data?.session]);
  useEffect(() => {
    fetch(`${GET_EXECUTIVE_MEMBER_SESSIONS}`)
      .then((response) => response.json())
      .then((data) => {
        setSelectedOptions(data);
      })
      .catch(() => {});
  }, [id]);
  return (
    <section className='wpo-about-text' style={{ marginLeft: "45px" }}>
      <div className='row mb-4'>
        <div className='col-md-2  ' style={{ textAlign: "right" }}>
          <h5>Session :</h5>
        </div>

        <div className='col-lg-4 col-md-4 col-4 mb-4 form  '>
          <div className='form-field rounded'>
            <select
              className='form-control'
              onChange={updateData}
              type='text'
              name='session'
              id='session'
              style={{ borderRadius: "20px" }}>
              <option value='without_photo'>Select Session</option>
              {selectedOptions?.map((selectedOption, index) => (
                <option key={index} value={selectedOption}>
                  {selectedOption}
                </option>
              ))}
            </select>
          </div>
        </div>
      </div>
      {executiveCommittees?.length > 0 ? (
        <div>
          {" "}
          <div className=' mb-2 text-center'>
            <h4>
              BAIRA Executive Committee in {executiveCommittees?.[0]?.session}
              &nbsp; Session
            </h4>
          </div>
          <div className='container pe-0'>
            <table className='table table-hover table-responsive rounded'>
              <thead>
                <tr className='bg-secondary text-white'>
                  <th scope='col' style={{ textAlign: "center" }}>
                    SL
                  </th>
                  <th scope='col' style={{ textAlign: "center" }}>
                    Photo
                  </th>
                  <th scope='col' style={{ textAlign: "center" }}>
                    Name & Designation
                  </th>
                  <th scope='col' style={{ textAlign: "center" }}>
                    Address, Phone, Email & Web
                  </th>
                </tr>
              </thead>
              <tbody>
                {executiveCommittees?.map((executiveCommittee, index) => (
                  <tr
                    style={{ backgroundColor: index % 2 === 0 && "#E4F8FF" }}
                    key={index}>
                    <th scope='row'>{index + 1}</th>
                    <td className='p-2 text-center'>
                      {executiveCommittee?.member?.image ? (
                        <img
                          src={`${BASE_URL}${executiveCommittee?.member?.image}`}
                          className='rounded'
                          width='100'
                          loading='lazy'
                          height='100'
                          alt='...'
                        />
                      ) : (
                        <img
                          src='/user.png'
                          className='rounded'
                          width='100'
                          loading='lazy'
                          height='100'
                          alt='...'
                        />
                      )}
                    </td>
                    <td className='p-2 text-center'>
                      {executiveCommittee?.member?.owner_name} <br />
                      {executiveCommittee?.designation?.name} of BAIRA <br /> &
                      <br />
                      {executiveCommittee?.member?.designation} in &nbsp;
                      {executiveCommittee?.member?.agency?.name}
                      <br />
                      RL No:
                      {executiveCommittee?.member?.agency?.rl_no}
                    </td>
                    <td className='p-2'>
                      {executiveCommittee?.member?.home_address} <br />
                      Phone: {executiveCommittee?.member?.telephone} <br />
                      E-mail: {executiveCommittee?.member?.email} <br />
                      Web: {executiveCommittee?.member?.website} <br />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      ) : (
        <Loading />
      )}
    </section>
  );
};

export default ExecutiveCommittee;
