/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/jsx-no-target-blank */
import React, { useEffect, useState } from "react";
import { EMBASSY_LISTS, LOCAL_EMBASSY_LISTS } from "../../constant/constants";

const Loading = (props) => {
  return (
    <div className='text-center mt-5'>
      <img src='/loader.svg' alt='' loading='lazy' />
    </div>
  );
};

export default Loading;
