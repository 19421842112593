import React, { useEffect, useState } from "react";
import { BASE_URL, GET_CURRENT_EXECUTIVES } from "../../constant/constants";

const PresidentSecretaryGeneralSidebar = (props) => {
  const [executiveCommittees, setExecutiveCommittees] = useState([]);

  useEffect(() => {
    fetch(`${GET_CURRENT_EXECUTIVES}`)
      .then((response) => response.json())
      .then((data) => {
        setExecutiveCommittees(data);
      })
      .catch(() => {});
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props?.id]);

  return (
    <section
      className='wpo-features-section-s6 section-padding bg-white'
      style={{ paddingBottom: "5px" }}>
      <div className='container'>
        <div className='row'>
          {executiveCommittees?.map(
            (executiveCommittee, index) =>
              (executiveCommittee?.designation?.name === "President" ||
                executiveCommittee?.designation?.name ===
                  "Secretary General") && (
                <div
                  className='col col-xl-12 col-lg-12 col-sm-12 col-12 '
                  key={index}>
                  <div
                    className='wpo-features-item'
                    style={{
                      borderRadius: "20px",
                      marginBottom: "20px",
                      backgroundColor: "#EEE",
                      borderBottom: "3px solid #0067B4",
                    }}>
                    <div className=''>
                      <div className=''>
                        <img
                          style={{
                            borderRadius: "50%",
                            border: "2px solid #0067B4",
                            display: "block",
                            width: "150px",
                            marginLeft: "auto",
                            marginRight: "auto",
                            height: "150px",
                          }}
                          className='shadow-sm p-1 mb-2 bg-body rounded-circle'
                          src={`${BASE_URL}${executiveCommittee?.member?.image}`}
                          alt=''
                          loading='lazy'
                        />
                      </div>
                    </div>
                    <div className=''>
                      <h5 className='mx-auto text-center '>
                        {executiveCommittee?.member?.owner_name}
                      </h5>
                      <p className='mx-auto text-center '>
                        <b> {executiveCommittee?.designation?.name},BAIRA</b>
                      </p>
                    </div>
                  </div>
                </div>
              )
          )}
        </div>
      </div>
    </section>
  );
};

export default PresidentSecretaryGeneralSidebar;
