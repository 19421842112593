/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/jsx-no-target-blank */
import React, { useEffect, useState } from "react";
import { EMBASSY_LISTS, LOCAL_EMBASSY_LISTS } from "../../constant/constants";
import Loading from "../Loading";

const LocalEmbassy = (props) => {
  const [embassys, setEmbassys] = useState([]);
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [currentPage, setCurrentPage] = useState(1);
  //for content
  useEffect(() => {
    fetch(`${LOCAL_EMBASSY_LISTS}?page=${currentPage}&size=${rowsPerPage}`)
      .then((response) => response.json())
      .then((data) => {
        setEmbassys(data);
      })
      .catch(() => {});
  }, [currentPage, rowsPerPage]);

  const handlePageClick = (page) => {
    setCurrentPage(page);
    // Perform additional actions when a page is clicked (e.g., fetch data)
  };

  const renderPagination = () => {
    const paginationItems = [];

    // Add 'Previous' link
    paginationItems.push(
      <li
        key='previous'
        className={`page-item${currentPage === 1 ? " disabled" : ""}`}>
        <a
          className='page-link'
          href='#'
          tabIndex='-1'
          onClick={() => handlePageClick(currentPage - 1)}>
          Previous
        </a>
      </li>
    );

    // Add page links
    for (let i = 1; i <= embassys?.total_pages; i++) {
      paginationItems.push(
        <li
          key={i}
          className={`page-item${currentPage === i ? " active" : ""}`}>
          <a className='page-link' href='#' onClick={() => handlePageClick(i)}>
            {i}
          </a>
        </li>
      );
    }

    // Add 'Next' link
    paginationItems.push(
      <li
        key='next'
        className={`page-item${
          currentPage === embassys?.total_pages ? " disabled" : ""
        }`}>
        <a
          className='page-link'
          href='#'
          onClick={() => handlePageClick(currentPage + 1)}>
          Next
        </a>
      </li>
    );

    return paginationItems;
  };
  return (
    <section className='mb-5'>
      <div className='container mb-2'>
        <div className='row'>
          <div className=' col-md-12 text-center'>
            <h4>Bangladesh Representations in Abroad</h4>
          </div>
        </div>
      </div>
      <div className='container'>
        <div className='row'>
          {embassys?.embassy_lists?.length > 0 ? (
            <table className='table table-bordered table-striped'>
              <thead>
                <tr>
                  <th style={{ width: "50px" }} className='text-center'>
                    SL
                  </th>
                  <th className='text-center'>Name </th>
                  <th className='text-center'>Address,Email ,Phone,Fax </th>
                  <th className='text-center'>Map </th>
                </tr>
              </thead>
              <tbody>
                {embassys?.embassy_lists?.map((embassy, sitem) => (
                  <tr key={sitem}>
                    <td style={{ width: "50px" }} className='text-center'>
                      {sitem + 1}
                    </td>
                    <td className='text-center'>{embassy?.name}</td>
                    <td className='text-center'>
                      <i className='fa-solid fa-location-dot'></i>
                      {embassy?.street_address_one} <br />
                      <i className='fa-solid fa-fax'></i> {embassy?.fax} <br />
                      <i className='fa-solid fa-phone'></i>
                      {embassy?.primary_phone}
                      <br /> <i className='fa-solid fa-envelope'></i>
                      {embassy?.email}
                      <br /> <i className='fa-solid fa-globe'></i>
                      {embassy?.website}
                    </td>
                    <td className='text-center'>
                      <iframe
                        title='Map'
                        loading='lazy'
                        src={embassy?.map_link?.match(/src="([^"]+)"/)[1]}
                        style={{ width: "600px", height: "150px" }}></iframe>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          ) : (
            <Loading />
          )}

          <ul className='pagination'>{renderPagination()}</ul>
        </div>
      </div>
    </section>
  );
};

export default LocalEmbassy;
