import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {
  BASE_URL,
  GET_EX_PRESIDENT_SECRETARY_GENERAL,
} from "../../constant/constants";
import Loading from "../Loading";

const ExPresidentsAndSecretary = (props) => {
  const { id } = useParams();
  const [executiveCommittees, setExecutiveCommittees] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    setIsLoading(true);
    fetch(`${GET_EX_PRESIDENT_SECRETARY_GENERAL}`)
      .then((response) => response.json())
      .then((data) => {
        setExecutiveCommittees(data);
        setIsLoading(false);
      })
      .catch(() => {
        setIsLoading(false);
      });
  }, [id]);

  return (
    <section className='wpo-about-text' style={{ marginLeft: "45px" }}>
      {isLoading ? (
        <Loading />
      ) : executiveCommittees?.length > 0 ? (
        <div>
          <div className='pt-4 mx-2 text-center'>
            <h3>Ex-President's and Secretary General's</h3>
          </div>
          <div className='container'>
            <table className='table table-hover table-responsive'>
              <thead>
                <tr className='bg-secondary text-white'>
                  <th scope='col'>Photo</th>
                  <th scope='col'>Name of President</th>
                  <th scope='col'>Photo</th>
                  <th scope='col'>Name of Secretary General</th>
                </tr>
              </thead>
              <tbody>
                {executiveCommittees.map((executiveCommittee, index) => (
                  <tr
                    key={index}
                    style={{ backgroundColor: index % 2 === 0 && "#E4F8FF" }}>
                    <td className='p-2 text-center'>
                      {executiveCommittee?.president?.member?.image ? (
                        <img
                          src={`${BASE_URL}${executiveCommittee?.president?.member?.image}`}
                          className='rounded'
                          width='100'
                          height='100'
                          loading='lazy'
                          alt='...'
                        />
                      ) : (
                        <img
                          src='/user.png'
                          className='rounded'
                          width='100'
                          height='100'
                          loading='lazy'
                          alt='...'
                        />
                      )}
                    </td>
                    <td className='p-2 text-center'>
                      <b>
                        {" "}
                        {executiveCommittee?.president?.member?.owner_name}
                      </b>
                      <br /> {executiveCommittee?.president?.designation?.name}{" "}
                      of BAIRA
                      <br /> & <br />
                      {executiveCommittee?.president?.date_from} -{" "}
                      {executiveCommittee?.president?.date_to}
                    </td>
                    <td className='p-2 text-center'>
                      {executiveCommittee?.secretary_general?.member?.image ? (
                        <img
                          src={`${BASE_URL}${executiveCommittee?.secretary_general?.member?.image}`}
                          className='rounded'
                          width='100'
                          height='100'
                          alt='...'
                          loading='lazy'
                        />
                      ) : (
                        <img
                          src='/user.png'
                          className='rounded'
                          width='100'
                          height='100'
                          loading='lazy'
                          alt='...'
                        />
                      )}
                    </td>
                    <td className='p-2 text-center'>
                      <b>
                        {
                          executiveCommittee?.secretary_general?.member
                            ?.owner_name
                        }
                      </b>
                      <br />
                      {
                        executiveCommittee?.secretary_general?.designation?.name
                      }{" "}
                      of BAIRA
                      <br /> & <br />
                      {executiveCommittee?.secretary_general?.date_from} -{" "}
                      {executiveCommittee?.secretary_general?.date_to}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      ) : (
        <p>There are no Ex-President's and Secretary General's.</p>
      )}
    </section>
  );
};

export default ExPresidentsAndSecretary;
