import React, { useEffect, useState } from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from 'react-router-dom';

import Homepage from '../HomePage';
import HomePageForMember from '../HomePageForMember';
import ContactPage from '../ContactPage';
import ErrorPage from '../ErrorPage';
import LoginPage from '../LoginPage';
import SignUpPage from '../SignUpPage';
import ForgotPassword from '../ForgotPassword';
import HistoryPage from '../HistoryPage';
import NoticePage from '../NoticePage';
import GalleryPage from '../GalleryPage';
import FormPage from '../FormPage';
import MemberPage from '../MemberPage';
import {
  GET_MENUS_ALL_NESTED,
  GET_SITESETTINGS,
} from '../../constant/constants';
import Navbar2 from '../../components/Navbar2';
import ProfilePage from '../ProfilePage';
import { useDispatch } from 'react-redux';
import { getAllmenus, getSiteSetting } from '../../dataSlice/dataSlice';
import AimsAndObjectivePage from '../AimsAndObjectivePage';
import HistoryAndBackgroundPage from '../HistoryAndBackgroundPage';
import ExPresidentsAndSecretaryPage from '../ExPresidentsAndSecretaryPage';
import PresidentMessagePage from '../PresidentMessagePage';
import SecretaryGeneralMessagePage from '../SecretaryGeneralMessagePage';
import ExecutiveCommitteePage from '../ExecutiveCommitteePage';
import MemberDetailsPage from '../MemberDetailsPage';
import MemberSearchPage from '../MemberSearchPage';
import SecretariatPage from '../SecretariatPage';
import MemorandumArticlePage from '../MemorandumArticlePage';
import PdfShowPage from '../PdfShowPage';
import MinistryListPage from '../MinistryListPage';
import LocalEmbassyPage from '../LocalEmbassyPage';
import ForeignEmbassyPage from '../ForeignEmbassyPage';
import PaxCommentPage from '../PaxCommentPage';
import BlogPage from '../BlogPage';
import MemberBlogPage from '../MemberBlogPage';

const AllRoute = ({ logo }) => {
  const [homeId, setHomeId] = useState();
  const dispatch = useDispatch();
  const [siteSetting, setSiteSetting] = useState({});
  const [show, setShow] = useState(false);

  console.log(`history`, show, window.location.pathname);
  useEffect(() => {
    setShow(/^\/$/.test(window.location.pathname));

    fetch(`${GET_MENUS_ALL_NESTED}`)
      .then((response) => response.json())
      .then((data) => {
        data.menus.find((e) => (e.name === 'Home' ? setHomeId(e.id) : null));
      })
      .catch(() => {});
    dispatch(getAllmenus());
    dispatch(getSiteSetting());
  }, [dispatch]);
  useEffect(() => {
    fetch(`${GET_SITESETTINGS}`)
      .then((response) => response?.json())
      .then((data) => {
        setSiteSetting(data?.general_settings[0]);
      })
      .catch(() => {});
  }, []);

  return (
    <div className='App'>
      <Router>
        {show ? (
          <Navbar2
            Logo={logo}
            Facebook={siteSetting?.facebook_url}
            Twitter={siteSetting?.twitter_url}
            Youtube={siteSetting?.instagram_url}
            id={homeId}
            hclass={'wpo-header-style-4 text-nowrap'}
            style={{ display: !homeId && 'none' }}
          />
        ) : null}

        <Switch>
          <Route exact path='/' component={Homepage} />
          <Route path='/home/:id' component={Homepage} />
          <Route exact path='/:memberRlNo' component={HomePageForMember} />
          <Route exact path='/:memberRlNo/Member Post' component={MemberBlogPage} />

          <Route path='/notices/:id' component={NoticePage} />
          <Route path='/post/:id/:blogId?' component={BlogPage} />
          {/* <Route path='/blogs/:id/:blogId' component={BlogPage} /> */}
          <Route path='/history/:id' component={HistoryPage} />
          <Route
            path='/aims and objective/:id'
            component={AimsAndObjectivePage}
          />
          <Route
            path='/history & background/:id'
            component={HistoryAndBackgroundPage}
          />
          <Route
            path='/ex-presidents and secretary generals/:id'
            component={ExPresidentsAndSecretaryPage}
          />
          <Route
            path='/Message from President/:id'
            component={PresidentMessagePage}
          />
          <Route
            path='/memorandum & article of baira/:id'
            component={MemorandumArticlePage}
          />
          <Route
            path='/Message from Secretary General/:id'
            component={SecretaryGeneralMessagePage}
          />
          <Route
            path='/executive committee/:id'
            component={ExecutiveCommitteePage}
          />
          <Route path='/ministry list/:id' component={MinistryListPage} />
          <Route path='/local embassy/:id' component={LocalEmbassyPage} />
          <Route path='/foreign embassy/:id' component={ForeignEmbassyPage} />
          <Route path='/pdfShow/:type/:id' component={PdfShowPage} />
          <Route path='/gallery/:id' component={GalleryPage} />
          <Route path='/form/:id' component={FormPage} />
          <Route path='/Complain/:id' component={PaxCommentPage} />
          <Route path='/Secretariat/:id' component={SecretariatPage} />
          <Route path='/all member/:id' component={MemberPage} />
          <Route
            path='/member-search/:key/:type/:letter'
            component={MemberSearchPage}
          />
          <Route path='/all members list/:id' component={MemberPage} />
          <Route path='/member-details/:id' component={MemberDetailsPage} />
          <Route path='/404' component={ErrorPage} />
          <Route path='/contact' component={ContactPage} />
          <Route path='/login' component={LoginPage} />
          <Route path='/profile' component={ProfilePage} />
          <Route path='/register' component={SignUpPage} />
          <Route path='/forgot-password' component={ForgotPassword} />
          <Redirect to='/404' />
        </Switch>
      </Router>
    </div>
  );
};

export default AllRoute;
