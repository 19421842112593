import React, { Fragment, useEffect, useState } from "react";
import Hero from "../../components/hero";
import Footer from "../../components/footer";
import Scrollbar from "../../components/scrollbar";
import { GET_MENUS_ALL_NESTED } from "../../constant/constants";
import NoticesBanner from "../../components/NoticesBanner";
import PresidentSecretaryGeneral from "../../components/PresidentSecretaryGeneral";
import MemberSearchs from "../../components/MemberSearchs";
import NoticeForHome from "../../components/NoticeForHome";
import CommitmentSlogans from "../../components/CommitmentSlogans";
import AboutHistoryBackground from "../../components/AboutHistoryBackground";
import Mappage from "../../components/Mappage";
import Loading from "../../components/Loading";

const HomePage = () => {
  const [homeId, setHomeId] = useState();

  useEffect(() => {
    fetch(`${GET_MENUS_ALL_NESTED}`)
      .then((response) => response.json())
      .then((data) => {
        data.menus.find((e) => (e.name === "Home" ? setHomeId(e.id) : null));
      })
      .catch(() => {});

    window.scrollTo(10, 0);
  }, []);

  return (
    <Fragment>
      {homeId ? (
        <div>
          <Hero className='h-100' />
          <NoticesBanner tClass={"wpo-testimonial-area-s3 "} />
          <PresidentSecretaryGeneral
            id={homeId}
            tClass={"wpo-testimonial-area-s3 "}
          />
          <section className='wpo-features-section-s6 section-padding pb-0 pt-5'>
            <div className='container'>
              <div className='row'>
                <div
                  className='col col-xl-4 col-lg-4 col-sm-4 col-12 p-2 rounded'
                  style={{ visibility: homeId && "visible" }}>
                  <MemberSearchs tClass={"wpo-testimonial-area-s3 "} />
                </div>
                <div className='col col-xl-4 col-lg-4 col-sm-4 col-12 p-2 rounded'>
                  <NoticeForHome tClass={"wpo-testimonial-area-s3 "} />
                </div>
                <div className='col col-xl-4 col-lg-4 col-sm-4 col-12 p-2 rounded'>
                  <CommitmentSlogans
                    id={homeId}
                    tClass={"wpo-testimonial-area-s3 "}
                  />
                </div>
              </div>
            </div>
          </section>
          <AboutHistoryBackground
            id={homeId}
            tClass={"wpo-testimonial-area-s3 "}
          />
          <Mappage id={homeId} tClass={"wpo-testimonial-area-s3 "} />
          <Scrollbar />
          <Footer />
        </div>
      ) : (
        <Loading />
      )}
    </Fragment>
  );
};
export default HomePage;
