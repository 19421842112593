import React, { useEffect, useState } from "react";
import {
  BASE_URL,
  GET_EMPLOYEES_BY_DEPARTMENT_WITHOUT_PAGINATION,
  GET_SITESETTINGS,
} from "../../constant/constants";
import Loading from "../Loading";

const Contactpage = (props) => {
  const [secretariats, setSecretariats] = useState({});
  const [siteSetting, setSiteSetting] = useState({});

  //for content
  useEffect(() => {
    fetch(`${GET_EMPLOYEES_BY_DEPARTMENT_WITHOUT_PAGINATION}`)
      .then((response) => response.json())
      .then((data) => {
        setSecretariats(data);
      })
      .catch(() => {});
  }, []);

  useEffect(() => {
    fetch(`${GET_SITESETTINGS}`)
      .then((response) => response.json())
      .then((data) => {
        setSiteSetting(data.general_settings[0]);
      })
      .catch(() => {});
  }, []);
  return (
    <>
      {siteSetting?.id && secretariats ? (
        <div className='container'>
          <div className='mx-2 mb-4 text-center'>
            <h5 className='text-nowrap' style={{ lineHeight: "15px" }}>
              {siteSetting?.site_name}
            </h5>
            <p className='text-nowrap' style={{ lineHeight: "12px" }}>
              {siteSetting?.address}
            </p>
            <p className='text-nowrap' style={{ lineHeight: "12px" }}>
              Tel:{siteSetting?.phone}
            </p>
            <p className='text-nowrap' style={{ lineHeight: "12px" }}>
              E-Mail:{siteSetting?.email}
            </p>
          </div>
          {Object.entries(secretariats).map(([departmentName, employees]) => (
            <div key={departmentName}>
              <div className='pt-4 mx-2 text-center'>
                <h3>{departmentName}</h3>
              </div>
              <table className='table table-hover table-responsive'>
                <thead>
                  <tr className='bg-secondary text-white'>
                    <th scope='col'>SL</th>
                    <th scope='col'>Photo</th>
                    <th scope='col'>Name </th>
                    <th scope='col'>Designation</th>
                    <th scope='col'>Mobile</th>
                    <th scope='col'>Email</th>
                  </tr>
                </thead>
                <tbody>
                  {employees.map((employee, index) => (
                    <tr
                      key={index}
                      style={{ backgroundColor: index % 2 === 0 && "#E4F8FF" }}
                    >
                      <th scope='row'>{index + 1}</th>
                      <td className='p-2 text-center'>
                        <img
                          src={`${BASE_URL}${employee?.image}`}
                          className='rounded'
                          width='50'
                          loading='lazy'
                          height='50'
                          alt='...'
                        />
                      </td>
                      <td className='p-2'>
                        {employee?.first_name} {employee?.last_name}
                      </td>
                      <td className='p-2'>{employee?.designation?.name}</td>
                      <td className='p-2'>{employee?.primary_phone}</td>
                      <td className='p-2'>{employee?.email}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          ))}
        </div>
      ) : (
        <Loading />
      )}
    </>
  );
};

export default Contactpage;
