import React from "react";
import MemberSearchs from "../MemberSearchs";
import NoticeForHome from "../NoticeForHome";
import PresidentSecretaryGeneralSidebar from "../PresidentSecretaryGeneralSidebar";

const RightSideInfo = (props) => {
  return (
    <section>
      <div className='row p-0'>
        <div className='col-md-10 justify-content-center p-0'>
          <div className='row p-0'>
            <div className='col-md-12   rounded mx-auto p-0'>
              <PresidentSecretaryGeneralSidebar
                tClass={"wpo-testimonial-area-s3 "}
              />
            </div>
            <div className='col-md-12  mb-3  rounded mx-auto'>
              <MemberSearchs tClass={"wpo-testimonial-area-s3 "} />
            </div>
            <div className='col-md-12 '>
              <NoticeForHome tClass={"wpo-testimonial-area-s3 mb-5 "} />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default RightSideInfo;
