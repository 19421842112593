import React, { useState } from "react";
import Grid from "@material-ui/core/Grid";
import SimpleReactValidator from "simple-react-validator";
import { toast } from "react-toastify";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import { Link, withRouter } from "react-router-dom";

import "./style.scss";
import { BASE_URL, LOGIN_URL } from "../../constant/constants";

const LoginPage = (props) => {
  const [value, setValue] = useState({
    email: "saikat@gmail.com",
    password: "123456",
    remember: false,
  });

  const changeHandler = (e) => {
    setValue({ ...value, [e.target.name]: e.target.value });
    validator.showMessages();
  };

  const [validator] = React.useState(
    new SimpleReactValidator({
      className: "errorMessage",
    })
  );

  const submitForm = (e) => {
    e.preventDefault();
    if (validator.allValid()) {
      setValue({
        email: "",
        password: "",
        remember: false,
      });
      validator.hideMessages();

      //  saving products to mongodb by post method
      fetch(`${LOGIN_URL}`, {
        method: "POST",
        headers: {
          "content-type": "application/json",
          // authorization: `bearer ${localStorage.getItem("jwToken")}`,
        },
        body: JSON.stringify(value),
      })
        .then((res) => res.json())
        .then((result) => {
          localStorage.setItem("jwt_access_token", result.access);
          localStorage.setItem("user_id", result.id);
          localStorage.setItem("user_email", result.email);
          localStorage.setItem("first_name", result.first_name);
          localStorage.setItem("last_name", result.last_name);
          localStorage.setItem("user_name", result.username);
          localStorage.setItem("user_role", result);
          localStorage.setItem("street_address_one", result.street_address_one);
          localStorage.setItem("primary_phone", result.primary_phone);
          localStorage.setItem("user_image", `${BASE_URL}${result.image}`);
          if (result.access) {
            props.history.push("/");
          }
        });
    } else {
      validator.showMessages();
      toast.error("Empty field is not allowed!");
    }
  };
  return (
    <Grid className='loginWrapper'>
      <Grid className='loginForm'>
        <h2>Sign In</h2>
        <p>Sign in to your account</p>
        <form onSubmit={submitForm}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <TextField
                className='inputOutline'
                fullWidth
                placeholder='E-mail'
                value={value.email}
                variant='outlined'
                name='email'
                label='E-mail'
                InputLabelProps={{
                  shrink: true,
                }}
                onBlur={(e) => changeHandler(e)}
                onChange={(e) => changeHandler(e)}
              />
              {validator.message("email", value.email, "required|email")}
            </Grid>
            <Grid item xs={12}>
              <TextField
                className='inputOutline'
                fullWidth
                placeholder='Password'
                value={value.password}
                variant='outlined'
                name='password'
                type='password'
                label='Password'
                InputLabelProps={{
                  shrink: true,
                }}
                onBlur={(e) => changeHandler(e)}
                onChange={(e) => changeHandler(e)}
              />
              {validator.message("password", value.password, "required")}
            </Grid>
            <Grid item xs={12}>
              <Grid className='formAction'>
                {/* <FormControlLabel
                  control={
                    <Checkbox
                      checked={value.remember}
                      onChange={rememberHandler}
                    />
                  }
                  label="Remember Me"
                /> */}
                <Link to='/forgot-password'>Forgot Password?</Link>
              </Grid>
              <Grid className='formFooter'>
                <Button fullWidth className='cBtnTheme' type='submit'>
                  Login
                </Button>
              </Grid>
              {/* <Grid className="loginWithSocial">
                <Button className="facebook">
                  <i className="fa fa-facebook"></i>
                </Button>
                <Button className="twitter">
                  <i className="fa fa-twitter"></i>
                </Button>
                <Button className="linkedin">
                  <i className="fa fa-linkedin"></i>
                </Button>
              </Grid> */}
              <p className='noteHelp'>
                Don't have an account?
                <Link to='/form/45'>Create free account</Link>
              </p>
            </Grid>
          </Grid>
        </form>
        <div className='shape-img'>
          <i className='fi flaticon-honeycomb'></i>
        </div>
      </Grid>
    </Grid>
  );
};

export default withRouter(LoginPage);
