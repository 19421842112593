import React, { Fragment } from "react";
import Footer from "../../components/footer";
import Scrollbar from "../../components/scrollbar";
import RightSideInfo from "../../components/RightSideInfo";
import NoticesForAllPage from "../../components/NoticesForAllPage";
import HistoryAndBackground from "../../components/HistoryAndBackground";

const HistoryAndBackgroundPage = () => {
  return (
    <Fragment>
      <NoticesForAllPage />

      <section>
        <div className=' row  '>
          <div className='col-md-8  container'>
            <HistoryAndBackground />
          </div>
          <div className='col-md-4'>
            <RightSideInfo />
          </div>
        </div>
      </section>

      <Footer />
      <Scrollbar />
    </Fragment>
  );
};
export default HistoryAndBackgroundPage;
