import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import "./NoticeForHome.css";
import { GET_NOTICES_WITHOUT_PAGINATION } from "../../constant/constants";

const NoticeForHome = (props) => {
  const [notices, setNotices] = useState([]);
  const ClickHandler = () => {
    window.scrollTo(10, 0);
  };
  useEffect(() => {
    fetch(`${GET_NOTICES_WITHOUT_PAGINATION}`)
      .then((response) => response.json())
      .then((data) => {
        setNotices(data.notices);
      })
      .catch(() => {});
  }, []);

  return (
    <section
      style={{
        backgroundColor: "#EEE",
        borderRadius: "20px",
        borderBottom: "3px solid #0067B4",
        minHeight: "380px",
        marginLeft: "20px",

        maxHeight: "380px",
        visibility: notices?.length > 0 ? "visible" : "hidden",
      }}
      className='wpo-features-item p-5 mb-0'>
      <div className='px-4 text-center'>
        <h4>NOTICE</h4>
      </div>
      <div
        style={{
          fontSize: "14px",
          fontFamily: "sans-serif",
          fontWeight: "300",
          lineHeight: "1.42857143",
          textAlign: "justify",
          maxHeight: "250px", // Adjust the maximum height as needed
          // overflowY: "scroll",
        }}>
        <ul className='notice-list'>
          {notices?.slice(0, 7)?.map((notice, index) => (
            <li key={index}>
              <Link
                to={`/pdfShow/notice/${notice.id}`}
                style={{ cursor: "pointer" }}
                onClick={ClickHandler}>
                <span className='notice-number'>{notice?.title}</span>
              </Link>
            </li>
          ))}
        </ul>
      </div>

      <p className=' mt-3' style={{ textAlign: "right" }}>
        <Link onClick={ClickHandler} to={`/notices/65`}>
          See More
        </Link>
      </p>
    </section>
  );
};

export default NoticeForHome;
