/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import "react-toastify/dist/ReactToastify.css";
import { toast } from "react-toastify";
import { useRef } from "react";

import {
  GET_COUNTRIES_WITHOUT_PAGINATION,
  MEMBER_WITHOUT_PG,
} from "../../constant/constants";
import { saveComplain } from "../../dataSlice/dataSlice";
import { useDispatch } from "react-redux";

const PaxComment = (props) => {
  const dispatch = useDispatch();
  const [data, setData] = useState({});
  const [members, setMembers] = useState([]);
  const [countrys, setCountrys] = useState([]);
  const [selectedFile, setSelectedFile] = useState(null);
  const formRef = useRef(null);

  useEffect(() => {
    fetch(`${GET_COUNTRIES_WITHOUT_PAGINATION}`)
      .then((response) => response.json())
      .then((data) => {
        setCountrys(data?.countries);
      })
      .catch(() => {});
    fetch(`${MEMBER_WITHOUT_PG}`)
      .then((response) => response.json())
      .then((data) => {
        setMembers(data?.agencies);
      })
      .catch(() => {});
  }, [data?.session]);

  const updateData = (e) => {
    setData({
      ...data,
      [e.target.name]: e.target.value,
    });
  };

  const notify = () =>
    toast.success(`Complaint sent successfully!`, {
      position: toast.POSITION.TOP_LEFT,
    });
  const ClickHandler = (event) => {
    event.preventDefault();
    const formData = new FormData();
    formData.append("file", selectedFile);

    const complain = {
      agency: data?.agency,
      name: data?.name,
      phone: data?.phone,
      email: data?.email,
      passport: data?.passport,
      country: data?.country,
      complain: data?.complain,
      file: selectedFile,
    };

    dispatch(saveComplain(complain)).then((res) => {
      if (res.payload?.data?.id) {
        notify();
        formRef.current.reset();
        setSelectedFile(null);
      }
    });
    window.scrollTo(10, 0);
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    setSelectedFile(file);
  };

  return (
    <>
      <section className='wpo-contact-pg-section section-padding'>
        <div className='container'>
          <div className='row'>
            <div className='col col-lg-10 offset-lg-1'>
              <div className='wpo-contact-title'>
                <h2>Have Any Problem?</h2>
                <p>
                  It is crucial to minimize any potential distractions on the
                  page when submitting a complaint to ensure that the reader can
                  focus on the complaint content
                </p>
              </div>
              <div className='wpo-contact-form-area' style={{ margin: "30px" }}>
                <form className='form' ref={formRef}>
                  <div className='row justify-content-center'>
                    <div className='col-lg-12 col-md-12 col-12 mb-4'>
                      <div className='form-field'>
                        <select
                          className='form-control'
                          onChange={updateData}
                          type='text'
                          name='agency'>
                          <option value=''>Select Member</option>
                          {members?.map((member, index) => (
                            <option key={index} value={member?.id}>
                              {member?.name}-{member?.rl_no}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                    <div className='col-lg-12 col-md-12 col-12 mb-4'>
                      <div className='form-field'>
                        <input
                          name='name'
                          onChange={updateData}
                          type='text'
                          id='name'
                          placeholder='Name '
                        />
                      </div>
                    </div>

                    <div className='col-lg-12 col-md-12 col-12 mb-4 '>
                      <div className='form-field'>
                        <input
                          onChange={updateData}
                          id='passport'
                          name='passport'
                          placeholder='Passport'
                        />
                      </div>
                    </div>

                    <div className='col-lg-12 col-md-12 col-12 mb-4'>
                      <div className='form-field'>
                        <input
                          name='email'
                          onChange={updateData}
                          type='text'
                          id='email'
                          placeholder='Email '
                        />
                      </div>
                    </div>

                    <div className='col-lg-12 col-md-12 col-12 mb-4'>
                      <div className='form-field'>
                        <input
                          name='phone'
                          onChange={updateData}
                          type='text'
                          id='phone'
                          placeholder='Phone '
                        />
                      </div>
                    </div>
                    <div className='col-lg-12 col-md-12 col-12 mb-4'>
                      <div className='form-field'>
                        <select
                          className='form-control'
                          onChange={updateData}
                          type='text'
                          name='country'>
                          <option value=''>Select Country From</option>
                          {countrys?.map((country, index) => (
                            <option key={index} value={country?.id}>
                              {country?.name}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                    <div className='col-lg-12'>
                      <div className='form-field'>
                        <textarea
                          id='complain'
                          onChange={updateData}
                          name='complain'
                          placeholder='Complain'></textarea>
                      </div>
                    </div>

                    <div className='container justify-content-center text-center mt-1'>
                      <div className='upload-section'>
                        <input type='file' onChange={handleFileChange} />
                      </div>
                      <div className='pdf-preview-section'>
                        {selectedFile && (
                          <embed
                            src={URL.createObjectURL(selectedFile)}
                            type='application/pdf'
                            width='50%'
                            height='300px'
                          />
                        )}
                      </div>
                    </div>
                    <div className='col-lg-12'>
                      <div className='form-submit'>
                        <button onClick={ClickHandler} className='theme-btn'>
                          Send Message
                        </button>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
        <section className='wpo-contact-map-section'>
          <div className='wpo-contact-map'></div>
        </section>
      </section>
    </>
  );
};

export default PaxComment;
