/* eslint-disable no-unused-vars */
import React, { Fragment, useEffect, useState } from 'react';
import Scrollbar from '../../components/scrollbar';
import {
  GET_RL_WEBISTE_MENU_CMSS_ALL,
  GET_SITESETTINGS,
  GET_AGENCY_INFO,
} from '../../constant/constants';
import HeroForMember from '../../components/heroForMember/';
import Navbar2ForMember from '../../components/Navbar2ForMember';
import { useParams } from 'react-router-dom';
import RLWebsiteAbout from '../../components/RLWebsiteAbout';
import RLWebsiteFooter from '../../components/RLWebsiteFooter';
import RLWebsiteService from '../../components/RLWebsiteService';
import PartnerSection from '../../components/PartnerSection';
import Gallery from '../../components/Gallery';
import Profile from '../../components/Profile';
import Message from '../EventSinglePage2/alltab';
import Team from '../../components/Team';
import { useLocation } from 'react-router-dom';
import RLWebsiteContactpage from '../../components/RLWebsiteContactpage';
import RlWebsiteComplain from '../../components/RlWebsiteComplain';
import OverseasCircularEmployment from '../../components/OverseasCircularEmployment';
import RLWebsiteNotice from '../../components/RLWebsiteNotice';
import RLWebsiteNoticeBanner from '../../components/RLWebsiteNoticeBanner';

const HomePageForMember = () => {
  const [siteSetting, setSiteSetting] = useState({});
  const [rlNo, setRlNo] = useState();
  const [siteName, setSiteName] = useState('');
  const [homeId, setHomeId] = useState();
  const [serviceId, setServiceId] = useState();
  const [aboutId, setAboutId] = useState();
  const routeParams = useParams();
  const location = useLocation();

  const [profileId, setProfileId] = useState();
  const [galleryId, setGalleryId] = useState();
  const [messageId, setMessageId] = useState();
  const [contactId, setContactId] = useState();
  const [teamId, setTeamId] = useState();
  const [complainId, setComplainId] = useState();
  const [circularId, setCircularId] = useState();
  const [noticeId, setNoticeId] = useState();
  const [scroll, setScroll] = React.useState(0);
  const [memberId, setMemberId] = useState();

  const handleScroll = () => setScroll(document.documentElement.scrollTop);

  //for content
  useEffect(() => {
    fetch(`${GET_AGENCY_INFO}${rlNo}`)
      .then((response) => response.json())
      .then((data) => {
        setSiteName(data?.name);
        setMemberId(data?.id);
      })
      .catch(() => {});
  }, [rlNo]);
  useEffect(() => {
    setRlNo(routeParams?.memberRlNo);
    fetch(`${GET_SITESETTINGS}`)
      .then((response) => response?.json())
      .then((data) => {
        setSiteSetting(data?.general_settings[0]);
      })
      .catch(() => {});

    fetch(`${GET_RL_WEBISTE_MENU_CMSS_ALL}`)
      .then((response) => response.json())
      .then((data) => {
        data.menus.find((e) => (e.name === 'Home' ? setHomeId(e.id) : null));
        data.menus.find((e) =>
          e.name === 'Contact' ? setContactId(e.id) : null
        );
        data.menus.find((e) =>
          e.name === 'Circular' ? setCircularId(e.id) : null
        );
        data.menus.find((e) => (e.name === 'About' ? setAboutId(e.id) : null));
        data.menus.find((e) =>
          e.name === 'Notice' ? setNoticeId(e.id) : null
        );
        data.menus.find((e) => (e.name === 'Team' ? setTeamId(e.id) : null));
        data.menus.find((e) =>
          e.name === 'Complain' ? setComplainId(e.id) : null
        );
        data.menus.find((e) =>
          e.name === 'Message' ? setMessageId(e.id) : null
        );
        data.menus.find((e) =>
          e.name === 'Profile' ? setProfileId(e.id) : null
        );
        data.menus.find((e) =>
          e.name === 'Gallery' ? setGalleryId(e.id) : null
        );
        data.menus.find((e) =>
          e.name === 'Service' ? setServiceId(e.id) : null
        );
      })
      .catch(() => {});
  }, [routeParams.memberRlNo]);
  const hashMappings = {
    '#about': 'aboutSection',
    '#contact': 'contactSection',
    '#gallery': 'gallerySection',
    '#home': 'homeSection',
    '#message': 'messageSection',
    '#notice': 'noticeSection',
    '#circular': 'circularSection',
    '#profile': 'profileSection',
    '#team': 'teamSection',
    '#service': 'serviceSection',
    '#complain': 'complainSection',
  };

  const selectedSection = location.hash ? location.hash.toLowerCase() : '';
  console.log(`selectedSection`, selectedSection, hashMappings);

  useEffect(() => {
    if (selectedSection && hashMappings[selectedSection]) {
      const sectionId = hashMappings[selectedSection];
      const sectionElement = document.getElementById(sectionId);

      if (sectionElement) {
        // Scroll to the section with block: "start" option
        sectionElement.scrollIntoView({ behavior: 'auto', block: 'start' });

        // Scroll up by 50 pixels
        window.scrollBy(0, -100);
      }
    }
  }, [hashMappings, selectedSection]);

  const className =
    scroll > 80 ? 'fixed-navbar animated fadeInDown active' : 'fixed-navbar';

  return (
    <Fragment>
      <div>
        <div className={className}>
          <Navbar2ForMember
            Logo={siteSetting?.logo}
            rl_no={routeParams?.memberRlNo}
            siteName={siteName}
            Facebook={siteSetting?.facebook_url}
            Twitter={siteSetting?.twitter_url}
            Youtube={siteSetting?.instagram_url}
            hclass={'wpo-header-style-4 text-nowrap'}
            id={routeParams?.memberRlNo}
          />
        </div>
        <HeroForMember rl_no={rlNo} />
        <div id='aboutSection'>
          <RLWebsiteNoticeBanner rl_no={rlNo} />
        </div>
        <div id='aboutSection'>
          <RLWebsiteAbout rl_no={rlNo} id={aboutId} />
        </div>

        <div id='messageSection'>
          <Message id={messageId} rl_no={rlNo} />
        </div>
        <div id='profileSection'>
          <Profile id={profileId} rl_no={rlNo} />
        </div>
        <div id='serviceSection'>
          <RLWebsiteService rl_no={rlNo} id={serviceId} />
        </div>
        <div id='circularSection'>
          <OverseasCircularEmployment id={circularId} rl_no={rlNo} />
        </div>
        <div id='gallerySection'>
          <Gallery id={galleryId} rl_no={rlNo} />
        </div>
        <div id='teamSection'>
          <Team id={teamId} rl_no={rlNo} />
        </div>
        <div id='complainSection'>
          <RlWebsiteComplain id={complainId} rl_no={rlNo} memberId={memberId} />
        </div>
        <div id='noticeSection'>
          <RLWebsiteNotice id={noticeId} rl_no={rlNo} />
        </div>
        {/* <RLWebsiteFeatureWork rl_no={rlNo} id={homeId} /> */}

        {/* <PartnerSection id={homeId} rl_no={rlNo} /> */}

        <div id='contactSection'>
          <RLWebsiteContactpage rl_no={rlNo} id={contactId} />
        </div>
        <Scrollbar />
        <RLWebsiteFooter rl_no={rlNo} id={homeId} />
      </div>
    </Fragment>
  );
};
export default HomePageForMember;
