import React, { useEffect, useState } from "react";
import {
  GET_AGENCY_INFO,
  GET_RL_WEBISTE_MENU_CMSS_ALL,
} from "../../constant/constants";
import Header2ForMember from "../header2ForMember";

export default function Navbar2ForMember(props) {
  const [scroll, setScroll] = React.useState(0);
  const [menu, setMunu] = useState([]);
  const [translator, setTranslator] = useState();

  const handleScroll = () => setScroll(document.documentElement.scrollTop);

  React.useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  const [contents, setContents] = useState({});
  //for content
  useEffect(() => {
    fetch(`${GET_AGENCY_INFO}${props?.rl_no}`)
      .then((response) => response.json())
      .then((data) => {
        setContents(data);
      })
      .catch(() => {});
  }, [props.rl_no]);
  console.log(`contents`, contents);

  //for get menu
  React.useEffect(() => {
    fetch(`${GET_RL_WEBISTE_MENU_CMSS_ALL}`)
      .then((response) => response?.json())
      .then((data) => {
        setMunu(data?.menus);
      })

      .catch(() => {});
  }, []);
  const className =
    scroll > 80 ? "fixed-navbar animated fadeInDown active" : "fixed-navbar";

  React.useEffect(() => {
    setTranslator(document.getElementById("google_translate_element"));
  }, []);

  console.log(`menu`, menu);
  return (
    <div className={className}>
      <Header2ForMember
        id={props?.id}
        memberPost={props?.memberPost}
        menu={menu}
        rl_no={props?.rl_no}
        hclass={props?.hclass}
        Logo={contents?.logo}
        siteName={contents?.name}
        Facebook={props?.Facebook}
        Twitter={props?.Twitter}
        Youtube={props?.Youtube}
        translator={translator}
        topbarNone={props?.topbarNone}
      />
    </div>
  );
}
