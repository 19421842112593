import React, { useEffect, useState } from "react";
import {
  BASE_URL,
  GET_RESOURCES_WITHOUT_PAGINATION,
} from "../../constant/constants";

const MemorandumArticle = (props) => {
  const [resources, setResources] = useState([]);

  useEffect(() => {
    fetch(`${GET_RESOURCES_WITHOUT_PAGINATION}`)
      .then((response) => response.json())
      .then((data) => {
        data.resources.find((e) =>
          e.title === "Memorandum and Articles of Association of BAIRA"
            ? setResources(e)
            : null
        );
      })
      .catch(() => {});
  }, []);
  return (
    <section className='wpo-about-text' style={{ margin: "10px" }}>
      <div className=' mx-2 text-center'>
        <h3>Memorandum and Articles of Association of BAIRA</h3>
      </div>
      <div className='container row col-md-12'>
        <iframe
          src={`${BASE_URL}${resources?.file}`}
          title='W3Schools Free Online Web Tutorials'
          height='600px'
        ></iframe>
      </div>
    </section>
  );
};

export default MemorandumArticle;
