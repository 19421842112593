import React, { useEffect, useState } from "react";
import "react-toastify/dist/ReactToastify.css";

import {
  BASE_URL,
  GET_EMPLOYEES_BY_DEPARTMENT_WITHOUT_PAGINATION,
  GET_SITESETTINGS,
} from "../../constant/constants";
import Loading from "../Loading";

const Secretariat = (props) => {
  const [secretariats, setSecretariats] = useState({});
  const [siteSetting, setSiteSetting] = useState({});

  //for content
  useEffect(() => {
    fetch(`${GET_EMPLOYEES_BY_DEPARTMENT_WITHOUT_PAGINATION}`)
      .then((response) => response.json())
      .then((data) => {
        setSecretariats(data);
      })
      .catch(() => {});
  }, []);
  useEffect(() => {
    fetch(`${GET_SITESETTINGS}`)
      .then((response) => response.json())
      .then((data) => {
        setSiteSetting(data.general_settings[0]);
      })
      .catch(() => {});
  }, []);
  return (
    <>
      {siteSetting?.id && secretariats ? (
        <div className='container'>
          <div className='mx-2 mb-4 text-center'>
            <h3 className='text-nowrap' style={{ lineHeight: "15px" }}>
              BAIRA Secretariat
            </h3>
            <p className='text-nowrap' style={{ lineHeight: "12px" }}>
              {siteSetting?.address}
            </p>
            <p className='text-nowrap' style={{ lineHeight: "12px" }}>
              Tel:{siteSetting?.phone}
            </p>
            <p className='text-nowrap' style={{ lineHeight: "12px" }}>
              E-Mail:{siteSetting?.email}
            </p>
          </div>
          {Object.entries(secretariats).map(([departmentName, employees]) => (
            <div key={departmentName}>
              <div className='pt-4 mx-2 text-center'>
                <h4>{departmentName}</h4>
              </div>
              <table
                style={{ marginLeft: "40px", width: "95%" }}
                className=' table table-hover table-responsive'
              >
                <thead>
                  <tr className='bg-secondary text-white'>
                    <th scope='col' style={{ textAlign: "center" }}>
                      SL
                    </th>
                    <th scope='col' style={{ textAlign: "center" }}>
                      Photo
                    </th>
                    <th scope='col' style={{ textAlign: "center" }}>
                      Name
                    </th>
                    <th scope='col' style={{ textAlign: "center" }}>
                      Designation
                    </th>
                    <th scope='col' style={{ textAlign: "center" }}>
                      Mobile
                    </th>
                    <th scope='col' style={{ textAlign: "center" }}>
                      Email
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {employees.map((employee, index) => (
                    <tr
                      key={index}
                      style={{ backgroundColor: index % 2 === 0 && "#E4F8FF" }}
                    >
                      <th scope='row'>{index + 1}</th>
                      <td className='p-2 text-center'>
                        <img
                          src={`${BASE_URL}${employee?.image}`}
                          className='rounded'
                          width='50'
                          height='50'
                          alt='employee name'
                          loading='lazy'
                        />
                      </td>
                      <td className='p-2 text-center'>
                        {employee?.first_name} {employee?.last_name}
                      </td>
                      <td className='p-2 text-center'>
                        {employee?.designation?.name}
                      </td>
                      <td className='p-2 text-center'>
                        {employee?.primary_phone}
                      </td>
                      <td className='p-2 text-center'>{employee?.email}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          ))}
        </div>
      ) : (
        <Loading />
      )}
    </>
  );
};

export default Secretariat;
