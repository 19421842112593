import React, { useEffect, useState } from 'react';
import './hero.css';
import { BASE_URL, GET_SLIDERSETTINGS } from '../../constant/constants';

const Hero = () => {
  const [contents, setContents] = useState([]);
  const [currentSlideIndex, setCurrentSlideIndex] = useState(0);

  // Fetch slider settings
  useEffect(() => {
    fetch(GET_SLIDERSETTINGS)
      .then((response) => response.json())
      .then((data) => {
        setContents(data?.homepage_sliders || []);
      })
      .catch((error) => {
        console.error('Error fetching slider settings:', error);
      });
  }, []);

  // Function to handle next slide
  const nextSlide = () => {
    setCurrentSlideIndex((prevIndex) => (prevIndex + 1) % contents.length);
  };

  // Function to handle previous slide
  const prevSlide = () => {
    setCurrentSlideIndex((prevIndex) =>
      prevIndex === 0 ? contents.length - 1 : prevIndex - 1
    );
  };

  return (
    <div className='slider-container'>
      {contents.map((item, index) => (
        <div
          key={index}
          className={`slide ${index === currentSlideIndex ? 'active' : ''}`}>
          <img
            className='d-block w-100'
            height={600}
            loading='lazy'
            src={`${BASE_URL}${item.image}`}
            alt={`Slide ${index}`}
          />
        </div>
      ))}
      {/* Buttons for navigation */}
      <button className='prev' onClick={prevSlide}>
        <i class='fa-solid fa-arrow-left-long'></i>{' '}
      </button>
      <button className='next' onClick={nextSlide}>
        <i className='fa-solid fa-arrow-right'></i>
      </button>
    </div>
  );
};

export default Hero;
