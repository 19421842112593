import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { GET_CONTENTS_BY_MENU_ID } from "../../constant/constants";
import { Interweave } from "interweave";

function items(obj) {
  let content = [];

  // eslint-disable-next-line no-unused-vars
  for (let key in obj) {
    let contentItem = {};
    let objs = obj;

    contentItem[`${key}`] = objs[key];
    content.push(contentItem);
  }
  return content;
}

const PresidentMessage = (props) => {
  const { id } = useParams();
  const [contents, setContents] = useState("");

  useEffect(() => {
    fetch(`${GET_CONTENTS_BY_MENU_ID}/${id}`)
      .then((response) => response.json())
      .then((data) => {
        setContents(data.menu_contents);
      })
      .catch(() => {});
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, props?.id]);

  const newContents = items(contents);

  return (
    <section className='wpo-about-text' style={{ marginLeft: "45px" }}>
      <div className='pt-4 mx-2 text-center'>
        <h3>Message from President, BAIRA</h3>
      </div>
      <div className='container'>
        <div className='row align-items-center'>
          <div className='col-lg-12 col-md-12 col-12'>
            <div className='wpo-about-text'>
              {newContents.map((service, index) =>
                Object.entries(service).map(([key, value]) =>
                  key === "Message from President" ? (
                    <div className='' key={index}>
                      <Interweave
                        allowAttributes
                        allowElements
                        disableLineBreaks={true}
                        content={value}
                      />
                    </div>
                  ) : " " && key === "Message from President" ? (
                    <div className='' key={index}>
                      <Interweave
                        allowAttributes
                        allowElements
                        disableLineBreaks={true}
                        content={value}
                      />
                    </div>
                  ) : (
                    " "
                  )
                )
              )}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default PresidentMessage;
