import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { BASE_URL, GET_CIRCULAR_BY_ID } from '../../constant/constants';

const OverseasCircularEmployment = (props) => {
  const [circulars, setCirculars] = useState([]);
  const { id } = useParams();

  useEffect(() => {
    fetch(`${GET_CIRCULAR_BY_ID}?key=${props?.rl_no}`)
      .then((response) => response.json())
      .then((data) => {
        setCirculars(data?.employement_circular);
      })
      .catch(() => {});
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, props?.rl_no]);

  //for content items
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  return (
    <section className='wpo-project-section-s2 pb-0 section-padding py-5'>
      <div className='container-fluid'>
        <div className='sortable-gallery'>
          <div className='row'>
            <div className='col-lg-12'>
              <div className='project-grids gallery-active'>
                <div className={`wpo-campaign-area section-padding py-0`}>
                  <div className='container'>
                    <div className='row justify-content-center'>
                      <div className='col-lg-6'>
                        <div
                          className='wpo-section-title h1'
                          style={{ color: 'black' }}>
                          Overseas Employment Circular
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {circulars?.length > 0 ? (
                  <div
                    className='mx-auto px-1 table-responsive  row col-md-9 mx-auto text-center '
                    style={{ fontSize: windowWidth < 767 && '9px' }}>
                    <table className=' table table-hover table-responsive  bg-white mx-auto rounded border-1 border-current'>
                      <thead className='thead-dark'>
                        <tr className='bg-secondary text-white'>
                          <th scope='col' style={{ textAlign: 'center' }}>
                            SL
                          </th>
                          <th scope='col' style={{ textAlign: 'center' }}>
                            Title
                          </th>
                          <th scope='col' style={{ textAlign: 'center' }}>
                            Company
                          </th>
                          <th scope='col' style={{ textAlign: 'center' }}>
                            Country
                          </th>
                          <th scope='col' style={{ textAlign: 'center' }}>
                            Profession
                          </th>
                          <th scope='col' style={{ textAlign: 'center' }}>
                            Salary
                          </th>{' '}
                          <th scope='col' style={{ textAlign: 'center' }}>
                            Show
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {circulars?.map(
                          (circular, index) =>
                            circular?.is_active && (
                              <tr
                                key={index}
                                style={{
                                  backgroundColor:
                                    index % 2 === 0 && 'rgb(234 245 226)',
                                }}>
                                <th scope='row text-center'>{index + 1}</th>
                                <td className='p-2 text-center'>
                                  {circular?.title}
                                </td>
                                <td className='p-2 text-center'>
                                  {circular?.company}
                                </td>
                                <td className='p-2 text-center'>
                                  {circular?.country?.name}
                                </td>
                                <td className='p-2 text-center'>
                                  {circular?.category}
                                </td>{' '}
                                <td className='p-2 text-center'>
                                  {circular?.salary}
                                </td>{' '}
                                <td className='p-2 text-center'>
                                  <i
                                    className='fa-regular fa-eye text-primary'
                                    style={{ cursor: 'pointer' }}
                                    onClick={() =>
                                      circular?.file &&
                                      window.open(
                                        `${BASE_URL}${circular?.file}`
                                      )
                                    }></i>
                                </td>
                              </tr>
                            )
                        )}
                      </tbody>
                    </table>
                  </div>
                ) : (
                  <div>
                    <h5 className='text-center'>
                      There are currently no vacancies available.
                    </h5>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default OverseasCircularEmployment;
