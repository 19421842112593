/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Link } from "react-router-dom";

import { BASE_URL, GET_ALL_MEMBERS } from "../../constant/constants";
import Loading from "../Loading";

const Member = (props) => {
  const [members, setMembers] = useState({});

  const { id } = useParams();
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(100);
  const [currentPage, setCurrentPage] = useState(1);
  const [showMode, setShowMode] = useState("without_photo");
  const [type, setType] = useState("");
  const [selectedOption, setSelectedOption] = useState("");
  const [data, setData] = useState({
    sorting: "agency",
    view_mode: "without_photo",
  });

  const updateData = (e) => {
    setData({
      ...data,
      [e.target.name]: e.target.value,
    });
  };
  const handleOptionChange = (event) => {
    setSelectedOption(event.target.value);
  };

  //for content images
  useEffect(() => {
    fetch(
      `${GET_ALL_MEMBERS}?sort_by=${data?.sorting}&page=${currentPage}&size=${rowsPerPage}`
    )
      .then((response) => response.json())
      .then((data) => {
        setMembers(data);
      })
      .catch(() => {});
  }, [id, props?.id, currentPage, data]);

  const handlePageClick = (page) => {
    setCurrentPage(page);
    // Perform additional actions when a page is clicked (e.g., fetch data)
  };

  const renderPagination = () => {
    const paginationItems = [];

    // Add 'Previous' link
    paginationItems.push(
      <li
        key='previous'
        className={`page-item${currentPage === 1 ? " disabled" : ""}`}>
        <a
          className='page-link'
          href='#'
          tabIndex='-1'
          onClick={() => handlePageClick(currentPage - 1)}>
          Previous
        </a>
      </li>
    );

    // Add page links
    for (let i = 1; i <= members?.total_pages; i++) {
      paginationItems.push(
        <li
          key={i}
          className={`page-item${currentPage === i ? " active" : ""}`}>
          <a className='page-link' href='#' onClick={() => handlePageClick(i)}>
            {i}
          </a>
        </li>
      );
    }

    // Add 'Next' link
    paginationItems.push(
      <li
        key='next'
        className={`page-item${
          currentPage === members?.total_pages ? " disabled" : ""
        }`}>
        <a
          className='page-link'
          href='#'
          onClick={() => handlePageClick(currentPage + 1)}>
          Next
        </a>
      </li>
    );

    return paginationItems;
  };

  return (
    <section className='mb-5'>
      <div className='container mb-2'>
        <div className='row'>
          <div className=' col-md-6 mb-2 '>
            <h4>All Member List</h4>
          </div>
          <div className='col-md-3'>
            <div className='row '>
              <div className='col-lg-12 col-md-12 col-sm-12 col-12 form-group  form form-field'>
                <select
                  className='form-control px-3'
                  name='view_mode'
                  id='view_mode'
                  onChange={updateData}
                  style={{ borderRadius: "20px" }}>
                  <option value='without_photo'>Select View Mode</option>
                  <option value='with_photo'>With Photo</option>
                  <option value='without_photo'>Without Photo</option>
                </select>
              </div>
            </div>
          </div>
          <div className='col-md-3'>
            <div className='row'>
              <div className='col-lg-12 col-md-12 col-sm-12 col-12 form-group form form-field'>
                <select
                  className='form-control'
                  name='sorting'
                  id='sorting'
                  onChange={updateData}
                  style={{ borderRadius: "20px" }}>
                  <option value='agency'>Select Sorting</option>
                  <option value='rl_no'>RL No.</option>
                  <option value='agency_a_z'>Agency Name(A-Z)</option>
                </select>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='container'>
        {data?.sorting === "agency" && data?.view_mode === "without_photo" ? (
          <div className='row'>
            {/* Without Photo  */}

            {members.agencies?.length > 0 ? (
              <table className='table table-bordered table-striped'>
                <thead>
                  <tr>
                    <th style={{ width: "50px" }} className='text-center'>
                      SL
                    </th>
                    <th>Name of the Agencies </th>
                    <th style={{ width: "120px" }} className='text-center'>
                      Website
                    </th>{" "}
                    <th style={{ width: "120px" }} className='text-center'>
                      RL. No.
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {members.agencies?.map((memberMember, sitem) => (
                    <tr
                      key={sitem}
                      style={{ backgroundColor: sitem % 2 === 0 && "#E4F8FF" }}>
                      <td style={{ width: "50px" }} className='text-center'>
                        {(currentPage - 1) * 100 + sitem + 1}
                      </td>
                      <td>
                        <Link to={`/member-details/${memberMember?.id}`}>
                          {memberMember?.name}
                        </Link>
                      </td>
                      <td style={{ width: "120px" }} className='text-center'>
                        <a
                          href={`https://${memberMember?.website}`}
                          target='_blank'
                          rel='noopener noreferrer'>
                          {" "}
                          {memberMember?.website === "nan"
                            ? ""
                            : memberMember?.website}
                        </a>
                      </td>

                      <td style={{ width: "120px" }} className='text-center'>
                        {memberMember?.rl_no}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            ) : (
              <Loading />
            )}

            <ul className='pagination'>{renderPagination()}</ul>
          </div>
        ) : (
          <div className='row'>
            {/* Without Photo  */}
            {members.agencies?.length > 0 ? (
              <table className='table table-bordered table-striped'>
                <thead>
                  <tr>
                    <th style={{ width: "50px" }}>SL</th>
                    <th>Agency Name & RL No </th>
                    <th>Owner Name & Designation </th>
                    <th
                      style={{
                        display:
                          data?.view_mode === "with_photo" ? "block" : "none",
                      }}>
                      Photo
                    </th>
                    <th>Address </th>
                    <th>Mobile, Phone & Fax </th>
                    <th>Email & Website</th>
                  </tr>
                </thead>
                <tbody>
                  {members.agencies?.map((member, sitem) => (
                    <tr
                      key={sitem}
                      style={{ backgroundColor: sitem % 2 === 0 && "#E4F8FF" }}>
                      <td style={{ width: "50px" }} className='text-center'>
                        {(currentPage - 1) * 100 + sitem + 1}
                      </td>
                      <td className='text-center'>
                        {member?.name} <br /> RL NO :{member?.rl_no}
                      </td>
                      <td className='text-center'>
                        {member?.items?.owner_name} <br />
                        {member?.items?.designation}
                      </td>
                      <td
                        className='text-center'
                        style={{
                          display:
                            data?.view_mode === "with_photo" ? "block" : "none",
                        }}>
                        {member?.items?.image ? (
                          <img
                            src={`${BASE_URL}${member?.items?.image}`}
                            className='rounded'
                            width='100'
                            height='100'
                            loading='lazy'
                            alt='...'
                          />
                        ) : (
                          <img
                            src='/user.png'
                            className='rounded'
                            width='100'
                            loading='lazy'
                            height='100'
                            alt='...'
                          />
                        )}
                      </td>
                      <td>{member?.items?.home_address}</td>
                      <td>Mobile:{member?.items?.telephone}</td>
                      <td>
                        Email:{member?.items?.email}
                        <br />
                        Website:
                        <a
                          href={`https://${member?.website}`}
                          target='_blank'
                          rel='noopener noreferrer'>
                          {member?.website === "nan" ? "" : member?.website}
                        </a>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            ) : (
              <Loading />
            )}

            <ul className='pagination'>{renderPagination()}</ul>
          </div>
        )}
      </div>
    </section>
  );
};

export default Member;
