import React, { Fragment, useEffect, useState } from "react";
import Footer from "../../components/footer";
import Scrollbar from "../../components/scrollbar";
import { GET_MENUS_ALL_NESTED } from "../../constant/constants";
import Member from "../../components/Member";
import NoticesForAllPage from "../../components/NoticesForAllPage";

const MemberPage = () => {
  const [memberId, setMemberId] = useState();

  useEffect(() => {
    fetch(`${GET_MENUS_ALL_NESTED}`)
      .then((response) => response.json())
      .then((data) => {
        data.menus.find((e) =>
          e.name === "All Members List" ? setMemberId(e.id) : null
        );
      })
      .catch(() => {});
  }, []);

  return (
    <Fragment>
      <NoticesForAllPage />

      <section>
        <div className=' row  '>
          <div className='col-md-12   container'>
            <Member id={memberId} />
          </div>
        </div>
      </section>

      <Footer />
      <Scrollbar />
    </Fragment>
  );
};
export default MemberPage;
