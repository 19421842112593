import React, { useEffect, useState } from "react";
import { BASE_URL, GET_CURRENT_EXECUTIVES } from "../../constant/constants";

const PresidentSecretaryGeneral = (props) => {
  const [executiveCommittees, setExecutiveCommittees] = useState([]);

  useEffect(() => {
    fetch(`${GET_CURRENT_EXECUTIVES}`)
      .then((response) => response.json())
      .then((data) => {
        setExecutiveCommittees(data);
      })
      .catch(() => {});
  }, []);

  return (
    <section className='wpo-features-section-s6 section-padding pb-0'>
      <div className='container'>
        <div className='row'>
          {executiveCommittees?.map(
            (executiveCommittee, index) =>
              (executiveCommittee?.designation?.name === "President" ||
                executiveCommittee?.designation?.name ===
                  "Secretary General") && (
                <div
                  className='col col-xl-6 col-lg-6 col-sm-6 col-12 px-5'
                  key={index}>
                  <div
                    className='wpo-features-item'
                    style={{
                      margin: "10px",
                      padding: "15px 0px",
                      borderRadius: "20px",
                      backgroundColor: "#EEE",
                      borderBottom: "3px solid #0067B4",
                    }}>
                    <div className=''>
                      <div className=''>
                        <img
                          style={{
                            borderRadius: "50%",
                            border: "2px solid #0067B4",
                            display: "block",
                            width: "150px",
                            marginLeft: "auto",
                            marginRight: "auto",
                            height: "150px",
                          }}
                          className='shadow-sm p-1 mb-2 bg-body rounded-circle'
                          src={`${BASE_URL}${executiveCommittee?.member?.image}`}
                          alt=''
                          loading='lazy'
                        />
                      </div>
                    </div>
                    <div className='text-nowrap'>
                      <h4 className='mx-auto text-center '>
                        {executiveCommittee?.member?.owner_name}
                      </h4>
                      <h6 className='mx-auto text-center '>
                        {executiveCommittee?.designation?.name},BAIRA
                      </h6>
                    </div>
                  </div>
                </div>
              )
          )}
        </div>
      </div>
    </section>
  );
};

export default PresidentSecretaryGeneral;
