/* eslint-disable no-unused-vars */
import React, { useState } from "react";
import { Link } from "react-router-dom";
import "./style.css";

const MobileMenuForRLWebsite = ({ menu,rlNo,memberPost }) => {

  console.log("menu", menu)

  const [isMenuShow, setIsMenuShow] = useState(false);
  const [isOpen, setIsOpen] = useState(0);

  const menuHandler = () => {
    setIsMenuShow(!isMenuShow);
  };

  const handleSetIsOpen = (id) => () => {
    setIsOpen((prevId) => (id === prevId ? 0 : id));
    setIsMenuShow(!isMenuShow);
  };

  return (
    <div>
      <div className={`mobileMenu ${isMenuShow ? "show" : ""}`}>
        <ul className='responsivemenu'>
          {menu?.map((n) => (
            <ul className='nav navbar-nav mb-lg-0' key={n.id}>
              <li className='menu-item-has-children px-1'>
                <Link onClick={handleSetIsOpen(n.id)}  to={n?.name === "Member Post" ? `${rlNo}/${n?.name}` :memberPost ? `/${rlNo}` : `#${n?.name}`}>
                  {n?.name === "Team"
                    ? "Management Team"
                    : n?.name === "Profile"
                      ? "Company Profile"
                      : n?.name === "Member Post"
                            ? "Post"
                    : n?.name === "Notice"
                    ? "BMET Notice"
                    : n?.name === "Circular"
                    ? "Overseas Employment Circular"
                    : n?.name}
                </Link>
              </li>
            </ul>
          ))}
        </ul>
      </div>

      <div className='showmenu' onClick={menuHandler}>
        <button type='button' className='navbar-toggler open-btn'>
          <span className='icon-bar first-angle'></span>
          <span className='icon-bar middle-angle'></span>
          <span className='icon-bar last-angle'></span>
        </button>
      </div>
    </div>
  );
};

export default MobileMenuForRLWebsite;
