/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { GET_CONTENTS_BY_MENU_ID } from "../../constant/constants";

function items(obj) {
  let content = [];

  for (let key in obj) {
    let contentItem = {};
    let objs = obj;

    contentItem[`${key}`] = objs[key];
    content.push(contentItem);
  }
  return content;
}
const Mappage = (props) => {
  const [contents, setContents] = useState("");

  useEffect(() => {
    fetch(`${GET_CONTENTS_BY_MENU_ID}/${props?.id}`)
      .then((response) => response.json())
      .then((data) => {
        setContents(data.menu_contents);
      })
      .catch(() => {});
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props?.id]);

  const newContents = items(contents);

  return (
    <section
      style={{ visibility: contents ? "visible" : "hidden" }}
      className='wpo-contact-pg-section section-padding'
    >
      <section className='wpo-contact-map-section'>
        <div className='wpo-contact-map'>
          {newContents.map((service, index) =>
            Object.entries(service).map(([key, value]) =>
              key === "Map" ? (
                <span key={index}>
                  <iframe
                    key={key}
                    title='Map'
                    src={value?.match(/src="([^"]+)"/)[1]}
                  ></iframe>
                </span>
              ) : (
                " "
              )
            )
          )}
        </div>
      </section>
    </section>
  );
};

export default Mappage;
