import React, { useEffect, useState } from 'react';
import Marquee from 'react-fast-marquee';
import { useDispatch } from 'react-redux';
import { getAllnotices } from '../../dataSlice/dataSlice';
import { BASE_URL, GET_RL_WEBSITE_NOTICE } from '../../constant/constants';

const RLWebsiteNoticeBanner = (props) => {
  const dispatch = useDispatch();
  const [notices, setNotices] = useState([]);

  //for content
  useEffect(() => {
    fetch(`${GET_RL_WEBSITE_NOTICE}?key=${props?.rl_no}`)
      .then((response) => response.json())
      .then((data) => {
        setNotices(data.member_notices ? data.member_notices : []);
      })
      .catch(() => {});
  }, [props.rl_no]);

  useEffect(() => {
    dispatch(getAllnotices());
  }, [dispatch]);

  return (
    <section
      className=' justify-center mx-5 rounded  text-white text-center d-flex mx-auto py-1 px-2'
      style={{
        padding: '2px',
        marginTop: '20px',
        marginBottom: '20px',
        whiteSpace: 'nowrap',
        width: '90%',
        backgroundColor: '#0E89CB',
        borderBottom: '2px solid gray',
        fontFamily: 'Roboto, sans-serif',
        fontSize: '12px',
        fontWeight: '300',
        lineHeight: '35px',
        wordSpacing: '0px',
      }}>
      <span style={{ fontWeight: '500' }}>NOTICES:</span>
      <Marquee
        pauseOnHover='true'
        style={{ cursor: 'pointer' }}
        className='ms-4'>
        {notices?.map((notice, index) => (
          <i
            className='fa-solid fa-pen-to-square'
            style={{ cursor: 'pointer' }}
            onClick={() => window.open(`${BASE_URL}${notice.file}`)}>
            {' '}
            <span className='pe-4'>{notice?.title}</span>
          </i>
        ))}
      </Marquee>
    </section>
  );
};

export default RLWebsiteNoticeBanner;
