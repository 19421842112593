import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";

import { BASE_URL, GET_PDF_DATA } from "../../constant/constants";
import Loading from "../Loading";

const PdfShow = (props) => {
  const routeParams = useParams();

  const [data, setData] = useState({});

  useEffect(() => {
    fetch(`${GET_PDF_DATA}?type=${routeParams?.type}&id=${routeParams?.id}`)
      .then((response) => response.json())
      .then((data) => {
        setData(data);
      })
      .catch(() => {});
  }, [routeParams.id, routeParams.type]);
  return (
    <>
      <div className='container'>
        <div className='pt-4 mx-2 text-center'>
          <h3>{data?.title}</h3>
        </div>
        {data ? (
          <div className='col-md-12 mt-4 mx-auto'>
            <iframe
              // src={`${BASE_URL}${data?.file}`}
              src={`${BASE_URL}${data?.file}`}
              title='W3Schools Free Online Web Tutorials'
              height='600px'
              width='100%'
            ></iframe>
          </div>
        ) : (
          <Loading />
        )}
      </div>
    </>
  );
};

export default PdfShow;
