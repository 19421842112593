import { Interweave } from "interweave";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Slider from "react-slick";
import {
  BASE_URL,
  GET_RL_WEBSITE_CONTENTS_BY_MENU_ID,
  GET_RL_WEBSITE_IMAGE_BY_MENU_ID,
} from "../../constant/constants";

var settings = {
  dots: false,
  arrows: false,
  speed: 1000,
  slidesToShow: 3,
  slidesToScroll: 1,
  autoplay: true,
  responsive: [
    {
      breakpoint: 1500,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 1200,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 991,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 767,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
  ],
};
function items(obj) {
  let content = [];

  // eslint-disable-next-line no-unused-vars
  for (let key in obj) {
    let contentItem = {};
    let objs = obj;

    contentItem[`${key}`] = objs[key];
    content.push(contentItem);
  }
  return content;
}
const Gallery = (props) => {
  const [contents, setContents] = useState("");
  const [contentsImage, setContentsImage] = useState("");

  const { id } = useParams();
  console.log("projecID", id);

  //for content images
  useEffect(() => {
    fetch(`${GET_RL_WEBSITE_IMAGE_BY_MENU_ID}/${props.id}/${props.rl_no}`)
      .then((response) => response.json())
      .then((data) => {
        // setContentImages(data.content_images);
        setContentsImage(data.content_images);
        console.log("imagesContent", data.content_images);
        Object.entries(data.content_images).map(([key, value]) =>
          console.log("imageValue", key, value)
        );
      })
      .catch(() => {});
  }, [id, props.id, props.rl_no]);

  //for content
  useEffect(() => {
    fetch(`${GET_RL_WEBSITE_CONTENTS_BY_MENU_ID}/${props.id}/${props.rl_no}`)
      .then((response) => response.json())
      .then((data) => {
        setContents(data.menu_contents);

        console.log("allmenucontent", data.menu_contents);
      })
      .catch(() => {});
  }, [id, props.id, props.rl_no]);

  //for content items
  const newContents = items(contents);

  return (
    <section className='wpo-project-section-s2 pb-0 section-padding py-5'>
      <div className='container-fluid'>
        <div className='sortable-gallery'>
          <div className='row'>
            <div className='col-lg-12'>
              <div className='project-grids gallery-active'>
                <div className={`wpo-campaign-area section-padding py-0`}>
                  <div className='container'>
                    <div className='row justify-content-center'>
                      <div className='col-lg-6'>
                        <div className='wpo-section-title'>
                          {newContents.map((service, index) =>
                            Object.entries(service).map(([key, value]) =>
                              key === "Gallery" ? (
                                <div className='' key={index}>
                                  <Interweave
                                    allowAttributes
                                    allowElements
                                    disableLineBreaks={true}
                                    content={value}
                                  />
                                </div>
                              ) : (
                                " "
                              )
                            )
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <Slider {...settings}>
                  {Object.entries(contentsImage ? contentsImage : {}).map(
                    ([key, value]) =>
                      key === "Gallery"
                        ? value.map((n) => (
                            <div className='grid'>
                              <div className='img-holder px-5'>
                                <img
                                  src={`${BASE_URL}/media/${n}`}
                                  alt=''
                                  height='500px'
                                  className='shadow-sm p-3 mb-5 bg-body rounded'
                                  // style={{ borderRadius: "30px" }}
                                />
                              </div>
                            </div>
                          ))
                        : " "
                  )}
                </Slider>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Gallery;
