/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Link } from "react-router-dom";

import { GET_SEARCH } from "../../constant/constants";
import Loading from "../Loading";

const MemberSearch = (props) => {
  const [members, setMembers] = useState({});
  const { key, type, letter } = useParams();
  const rowsPerPage = 100;
  const [currentPage, setCurrentPage] = useState(1);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    setIsLoading(true);

    fetch(
      `${GET_SEARCH}/?key=${key === "0" ? "" : key}&type=${
        type === "0" ? "" : type
      }&letter=${
        letter === "0" ? "" : letter
      }&page=${currentPage}&size=${rowsPerPage}`
    )
      .then((response) => response.json())
      .then((data) => {
        setMembers(data);
        setIsLoading(false);
      })
      .catch(() => {
        setIsLoading(false);
      });
  }, [key, type, letter, currentPage]);

  const handlePageClick = (page) => {
    setCurrentPage(page);
    // Perform additional actions when a page is clicked (e.g., fetch data)
  };

  const renderPagination = () => {
    const paginationItems = [];

    // Add 'Previous' link
    paginationItems.push(
      <li
        key='previous'
        className={`page-item${currentPage === 1 ? " disabled" : ""}`}>
        <a
          className='page-link'
          href='#'
          tabIndex='-1'
          onClick={() => handlePageClick(currentPage - 1)}>
          Previous
        </a>
      </li>
    );

    // Add page links
    for (let i = 1; i <= members?.total_pages; i++) {
      paginationItems.push(
        <li
          key={i}
          className={`page-item${currentPage === i ? " active" : ""}`}>
          <a className='page-link' href='#' onClick={() => handlePageClick(i)}>
            {i}
          </a>
        </li>
      );
    }

    // Add 'Next' link
    paginationItems.push(
      <li
        key='next'
        className={`page-item${
          currentPage === members?.total_pages ? " disabled" : ""
        }`}>
        <a
          className='page-link'
          href='#'
          onClick={() => handlePageClick(currentPage + 1)}>
          Next
        </a>
      </li>
    );

    return paginationItems;
  };

  return (
    <section>
      <div className='container'>
        <div className='row'>
          {isLoading ? (
            <Loading />
          ) : members.agencies?.length > 0 ? (
            <table className='table table-bordered table-striped'>
              <thead>
                <tr>
                  <th>SL No.</th>
                  <th>Name of the Agencies</th>
                  <th>Website</th>
                  <th>RL. No.</th>
                </tr>
              </thead>
              <tbody>
                {members.agencies?.map((memberMember, sitem) => (
                  <tr key={sitem}>
                    <td> {(currentPage - 1) * 100 + sitem + 1}</td>
                    <td>
                      <Link to={`/member-details/${memberMember?.id}`}>
                        {memberMember?.name}
                      </Link>
                    </td>
                    <td style={{ width: "120px" }} className='text-center'>
                      <a
                        href={`https://${memberMember?.website}`}
                        target='_blank'
                        rel='noopener noreferrer'>
                        {" "}
                        {memberMember?.website === "nan"
                          ? ""
                          : memberMember?.website}
                      </a>{" "}
                    </td>
                    <td>{memberMember?.rl_no}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          ) : (
            <p>No Member found.</p>
          )}

          <ul className='pagination'>{renderPagination()}</ul>
        </div>
      </div>
    </section>
  );
};

export default MemberSearch;
