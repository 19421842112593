import React, { useEffect, useState } from "react";
import {
  BASE_URL,
  GET_CONTENTS_BY_MENU_ID,
  GET_IMAGE_BY_MENU_ID,
} from "../../constant/constants";
import { Interweave } from "interweave";
import shape from "@material-ui/core/styles/shape";

function items(obj) {
  let content = [];

  // eslint-disable-next-line no-unused-vars
  for (let key in obj) {
    let contentItem = {};
    let objs = obj;

    contentItem[`${key}`] = objs[key];
    content.push(contentItem);
  }
  return content;
}
const AboutHistoryBackground = (props) => {
  const [contents, setContents] = useState("");
  const [contentsImage, setContentsImage] = useState("");

  useEffect(() => {
    fetch(`${GET_CONTENTS_BY_MENU_ID}/${props?.id}`)
      .then((response) => response.json())
      .then((data) => {
        setContents(data.menu_contents);
      })
      .catch(() => {});

    // Get Content Image
    fetch(`${GET_IMAGE_BY_MENU_ID}/${props?.id}`)
      .then((response) => response.json())
      .then((data) => {
        setContentsImage(data.content_images);
      })
      .catch(() => {});
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props?.id]);

  const newContents = items(contents);

  return (
    <div style={{ visibility: contents ? "visible" : "hidden" }}>
      <section className='wpo-about-section section-padding mt-4'>
        <div className='container'>
          <div className='row align-items-center'>
            <div className='col-lg-6 col-md-12 col-12'>
              <div className='wpo-about-wrap'>
                {Object.entries(contentsImage ? contentsImage : {}).map(
                  ([key, value]) =>
                    key === "About Baira" ? (
                      <div className='wpo-about-img' key={key}>
                        <img
                          loading='lazy'
                          style={{ height: "600px" }}
                          src={`${BASE_URL}/media/${value}`}
                          alt=''
                        />
                        <div className='wpo-ab-shape-1'>
                          <div className='s-s1'></div>
                          <div className='s-s2'></div>
                        </div>
                        <div className='wpo-ab-shape-2'>
                          <img src={shape} alt='' loading='lazy' />
                        </div>
                      </div>
                    ) : (
                      " "
                    )
                )}
              </div>
            </div>
            <div className='col-lg-6 col-md-12 col-12'>
              {newContents.map((service, index) =>
                Object.entries(service).map(([key, value]) =>
                  key === "About Baira" ? (
                    <div key={index} className='text-justify col-md-12'>
                      <Interweave
                        allowAttributes
                        allowElements
                        disableLineBreaks={true}
                        content={value}
                        className='text-justify '
                      />
                    </div>
                  ) : (
                    " "
                  )
                )
              )}
            </div>
          </div>
        </div>
      </section>

      <section className='wpo-about-section section-padding mt-4'>
        <div className='container'>
          <div className='row align-items-center'>
            <div className='col-lg-6 col-md-12 col-12'>
              <div className='wpo-about-wrap'>
                {Object.entries(contentsImage ? contentsImage : {}).map(
                  ([key, value]) =>
                    key === "History & Background of BAIRA" ? (
                      <div className='wpo-about-img' key={key}>
                        <img
                          loading='lazy'
                          style={{ height: "400px" }}
                          src={`${BASE_URL}/media/${value}`}
                          alt=''
                        />
                        <div className='wpo-ab-shape-1'>
                          <div className='s-s1'></div>
                          <div className='s-s2'></div>
                        </div>
                        <div className='wpo-ab-shape-2'>
                          <img src={shape} alt='' loading='lazy' />
                        </div>
                      </div>
                    ) : (
                      " "
                    )
                )}
              </div>
            </div>
            <div className='col-lg-6 col-md-12 col-12'>
              {newContents.map((service, index) =>
                Object.entries(service).map(([key, value]) =>
                  key === "History & Background of BAIRA" ? (
                    <div className='' key={index}>
                      <Interweave
                        allowAttributes
                        allowElements
                        disableLineBreaks={true}
                        content={value}
                        className='text-justify'
                      />
                    </div>
                  ) : (
                    " "
                  )
                )
              )}
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default AboutHistoryBackground;
