import React, { Fragment } from "react";
import Footer from "../../components/footer";
import Scrollbar from "../../components/scrollbar";
import NoticesForAllPage from "../../components/NoticesForAllPage";
import MemberSearch from "../../components/MemberSearch";

const MemberSearchPage = () => {
  return (
    <Fragment>
      <NoticesForAllPage />

      <section>
        <div className=' row  '>
          <div className='col-md-12   container'>
            <MemberSearch />
          </div>
        </div>
      </section>

      <Footer />
      <Scrollbar />
    </Fragment>
  );
};
export default MemberSearchPage;
