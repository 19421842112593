import { Interweave } from "interweave";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Slider from "react-slick";
import {
  BASE_URL,
  GET_MEMBER_RENEWAL_UP_TO_DATE,
  GET_OWNER_BY_RL,
  GET_RL_WEBSITE_CONTENTS_BY_MENU_ID,
  GET_RL_WEBSITE_IMAGE_BY_MENU_ID,
} from "../../constant/constants";

var settings = {
  dots: false,
  arrows: false,
  speed: 1000,
  slidesToShow: 3,
  slidesToScroll: 1,
  autoplay: true,
  responsive: [
    {
      breakpoint: 1500,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 1200,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 991,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 767,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
  ],
};
function items(obj) {
  let content = [];

  // eslint-disable-next-line no-unused-vars
  for (let key in obj) {
    let contentItem = {};
    let objs = obj;

    contentItem[`${key}`] = objs[key];
    content.push(contentItem);
  }
  return content;
}
const Profile = (props) => {
  const [contents, setContents] = useState("");
  const [contentsImage, setContentsImage] = useState("");
  const { id } = useParams();
  const [member, setMember] = useState({});
  const [year, setYear] = useState("");
  useEffect(() => {
    fetch(`${GET_OWNER_BY_RL}?key=${props?.rl_no}`)
      .then((response) => response.json())
      .then((searchedMemberData) => {
        setMember(searchedMemberData?.agencies[0]);
      });
    fetch(`${GET_MEMBER_RENEWAL_UP_TO_DATE}?rl_no=${props?.rl_no}`)
      .then((response) => response.json())
      .then((data) => {
        setYear(data?.year);
      });
  }, [props.rl_no]);
  //for content images
  useEffect(() => {
    fetch(`${GET_RL_WEBSITE_IMAGE_BY_MENU_ID}/${props.id}/${props.rl_no}`)
      .then((response) => response.json())
      .then((data) => {
        // setContentImages(data.content_images);
        setContentsImage(data.content_images);
        console.log("imagesContent", data.content_images);
        Object.entries(data.content_images).map(([key, value]) =>
          console.log("imageValue", key, value)
        );
      })
      .catch(() => {});
  }, [id, props.id, props.rl_no]);

  //for content
  useEffect(() => {
    fetch(`${GET_RL_WEBSITE_CONTENTS_BY_MENU_ID}/${props.id}/${props.rl_no}`)
      .then((response) => response.json())
      .then((data) => {
        setContents(data.menu_contents);

        console.log("allmenucontent", data.menu_contents);
      })
      .catch(() => {});
  }, [id, props.id, props.rl_no]);

  //for content items
  const newContents = items(contents);

  return (
    <section className='wpo-project-section-s2 pb-0 section-padding py-5'>
      <div className='container-fluid p-3'>
        <div className='sortable-gallery'>
          <div className='row'>
            <div className='col-lg-12'>
              <div className='project-grids gallery-active'>
                <div className={`wpo-campaign-area section-padding py-0`}>
                  <div className='container'>
                    <div className='row justify-content-center'>
                      <div className='col-lg-6'>
                        <div className='wpo-section-title py-0'>
                          {newContents.map((service, index) =>
                            Object.entries(service).map(([key, value]) =>
                              key === "Profile" ? (
                                <div className='' key={index}>
                                  <Interweave
                                    allowAttributes
                                    allowElements
                                    disableLineBreaks={true}
                                    content={value}
                                  />
                                </div>
                              ) : (
                                " "
                              )
                            )
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                {member?.items?.map(
                  (item) =>
                    item?.is_default && (
                      <div
                        className='border mx-3 p-3 row col-md-6 mx-auto mb-5 bg-white mx-3'
                        style={{
                          fontFamily: "Roboto, sansSerif",
                          borderRadius: "30px",
                        }}>
                        <div className='d-flex row'>
                          <div className='mx-auto py-3 col-md-4 text-center'>
                            {item?.image ? (
                              <img
                                style={{ width: "150px", height: "150px" }}
                                src={`${BASE_URL}${item?.image}`}
                                alt='owner'
                                loading='lazy'
                              />
                            ) : (
                              <img
                                style={{ width: "150px", height: "150px" }}
                                loading='lazy'
                                src='/user.png'
                                alt='owner'
                              />
                            )}
                          </div>
                          <div className='col-md-8'>
                            {" "}
                            <table className='table table-borderless bg-white'>
                              <tbody>
                                <tr>
                                  <th style={{ whiteSpace: "nowrap" }}>
                                    Name of Agency{" "}
                                  </th>
                                  <td>: {member?.name}</td>
                                </tr>
                                <tr>
                                  <th style={{ whiteSpace: "nowrap" }}>
                                    Recruiting Licence No.{" "}
                                  </th>
                                  <td>: {member?.rl_no}</td>
                                </tr>
                                <tr>
                                  <th style={{ whiteSpace: "nowrap" }}>
                                    BAIRA Member No.{" "}
                                  </th>
                                  <td>: {member?.rl_no}</td>
                                </tr>
                                <tr>
                                  <th style={{ whiteSpace: "nowrap" }}>
                                    Licence Type{" "}
                                  </th>
                                  <td>: {member?.agency_type}</td>
                                </tr>
                                <tr>
                                  <th style={{ whiteSpace: "nowrap" }}>
                                    Licence Status{" "}
                                  </th>
                                  <td>: Up to {year}</td>
                                </tr>
                                <tr>
                                  <th style={{ whiteSpace: "nowrap" }}>
                                    Contact Person{" "}
                                  </th>
                                  <td>: {item?.owner_name}</td>
                                </tr>
                                <tr>
                                  <th>Designation</th>
                                  <td>: {item?.designation}</td>
                                </tr>
                                <tr>
                                  <th>Nationality</th>
                                  <td>: Bangladeshi</td>
                                </tr>
                                <tr>
                                  <th>Address</th>
                                  <td>
                                    <span
                                      style={{
                                        display: "block",
                                        maxWidth: "350px",
                                        maxHeight: "3em", // Adjust this value based on your design
                                        overflow: "hidden",
                                        wordWrap: "break-word",
                                      }}>
                                      : {item?.home_address}
                                    </span>{" "}
                                  </td>
                                </tr>
                                <tr>
                                  <th>Telephone No. </th>
                                  <td>: {item?.telephone}</td>
                                </tr>
                                <tr>
                                  <th>Email</th>
                                  <td>: {item?.email}</td>
                                </tr>
                                <tr>
                                  <th>Web</th>
                                  <td>
                                    :{" "}
                                    <a
                                      href={`https://${member?.website}`}
                                      target='_blank'
                                      rel='noopener noreferrer'>
                                      {member?.website === "nan"
                                        ? ""
                                        : member?.website}
                                    </a>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    )
                )}

                <Slider {...settings}>
                  {Object.entries(contentsImage ? contentsImage : {}).map(
                    ([key, value]) =>
                      key === "Profile"
                        ? value.map((n) => (
                            <div className='grid'>
                              <div className='img-holder px-5'>
                                <img
                                  src={`${BASE_URL}/media/${n}`}
                                  alt=''
                                  className='shadow-sm p-3 mb-5 bg-body rounded'
                                />
                              </div>
                            </div>
                          ))
                        : " "
                  )}
                </Slider>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Profile;
