import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import { Carousel } from "react-bootstrap-v5";
import { BASE_URL, GET_IMAGE_BY_MENU_ID } from "../../constant/constants";

const CommitmentSlogans = (props) => {
  const { id } = useParams();
  const [contentsImage, setContentsImage] = useState("");

  useEffect(() => {
    // Get Content Image
    fetch(`${GET_IMAGE_BY_MENU_ID}/${id || props?.id}`)
      .then((response) => response.json())
      .then((data) => {
        setContentsImage(data.content_images);
      })
      .catch(() => {});
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, props?.id]);

  return (
    <section
      style={{
        backgroundColor: "#EEE",
        minHeight: "380px",
        maxHeight: "380px",
        borderRadius: "20px",
        borderBottom: "3px solid #0067B4",
        visibility: contentsImage ? "visible" : "hidden",
      }}
      className=' '
    >
      <div className='py-4 mx-2 text-center '>
        <h4>COMMITMENTS & SLOGANS</h4>
      </div>
      <Carousel fade>
        {Object.entries(contentsImage ? contentsImage : {}).map(
          ([key, value]) =>
            key === "Commitment & Slogans" &&
            value?.map((data, index) => (
              <Carousel.Item key={index}>
                <img
                  className='d-block w-100 rounded p-1'
                  height={280}
                  loading='lazy'
                  src={`${BASE_URL}/media/${data}`}
                  alt='First slide'
                  style={{ borderRadius: "50px" }}
                />
              </Carousel.Item>
            ))
        )}
      </Carousel>
    </section>
  );
};

export default CommitmentSlogans;
