import React, { useEffect, useState } from "react";
import {
  BASE_URL,
  GET_AGENCY_INFO,
  GET_RL_WEBSITE_IMAGE_BY_MENU_ID,
  GET_USEFUL_LINKS,
} from "../../constant/constants";

const RLWebsiteFooter = (props) => {
  const [contents, setContents] = useState({});
  const [contentsImage, setContentsImage] = useState("");
  const [resources, setResources] = useState([]);

  // for content images
  useEffect(() => {
    fetch(`${GET_USEFUL_LINKS}?key=${props.rl_no}`)
      .then((response) => response.json())
      .then((data) => {
        setResources(data?.UsefulLink_lists);
      })
      .catch(() => {});

    fetch(`${GET_RL_WEBSITE_IMAGE_BY_MENU_ID}/${props.id}/${props.rl_no}`)
      .then((response) => response.json())
      .then((data) => {
        setContentsImage(data.content_images);
      })
      .catch(() => {});
  }, [props.id, props.rl_no]);

  // for content
  useEffect(() => {
    fetch(`${GET_AGENCY_INFO}${props?.rl_no}`)
      .then((response) => response.json())
      .then((data) => {
        setContents(data);
      })
      .catch(() => {});
  }, [props.rl_no]);

  return (
    <footer className='wpo-site-footer'>
      <div className='wpo-upper-footer'>
        <div className='container'>
          <div className='row'>
            <div className='col-md-3 col-sm-6'>
              <div className='widget about-widget'>
                <div className='logo widget-title mx-auto text-center'>
                  <img
                    src={`${BASE_URL}${contents.logo}`}
                    alt='blog'
                    width='200px'
                    height='200px'
                  />
                </div>
                <p>
                  Welcome and open yourself to your truest love this year with
                  us! With the Release Process
                </p>
              </div>
            </div>
            <div className='col-md-3 px-2'>
              <div>
                <div className='widget-title'>
                  <h3>Useful Links</h3>
                </div>
                <ul style={{ listStyle: "disc", flexDirection: "column" }}>
                  {resources?.map((resource, index) => (
                    <li key={index}>
                      <a
                        href={resource?.link || "#"}
                        target='_blank'
                        rel='noopener noreferrer'>
                        <span className='text-white'>{resource?.name}</span>
                      </a>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
            <div className='col-md-3 col-sm-6'>
              <div className='widget wpo-service-link-widget'>
                <div className='widget-title'>
                  <h3>Contact </h3>
                </div>
                <div className='contact-ft'>
                  <p>
                    Would you have any enquiries. Please feel free to contact us
                  </p>
                  <ul>
                    <li>
                      <i className='fi flaticon-mail'></i>
                      {contents.agency_email}
                    </li>
                    <li>
                      <i className='fi flaticon-phone-call'></i>{" "}
                      {contents.phone_number}
                    </li>
                    <li>
                      <i className='fi flaticon-location'></i>{" "}
                      {contents.address}
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className='col-md-3 col-sm-6'>
              <div className='widget instagram'>
                <div className='widget-title'>
                  <h3 className='text-center'>Member of</h3>
                </div>
                <ul className='d-flex '>
                  {Object.entries(contentsImage ? contentsImage : {}).map(
                    ([key, value]) =>
                      key === "Partner"
                        ? value.map((n) => (
                            <li className='grid' key={key}>
                              <div className='img-holder'>
                                <img
                                  src={`${BASE_URL}/media/${n}`}
                                  alt=''
                                  className='img-fluid'
                                  style={{ borderRadius: "20px" }}
                                />
                              </div>
                            </li>
                          ))
                        : null
                  )}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='wpo-lower-footer'>
        <div className='container'>
          <div className='row'>
            <div className='col col-xs-12'>
              <p className='copyright'>
                &copy; 2022 {contents.first_name} Design By{" "}
                <a
                  href='http://bluebayit.com/'
                  target='_blank'
                  rel='noopener noreferrer'>
                  RAMS(Bluebay IT Limited)
                </a>
                . All Rights Reserved.
              </p>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default RLWebsiteFooter;
