/* eslint-disable jsx-a11y/img-redundant-alt */
import React, { useState, useEffect, useCallback } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import axios from 'axios';
import Photogrid from 'react-facebook-photo-grid';
import { useParams } from 'react-router-dom';
import moment from 'moment';
import { BASE_URL, GET_BLOGS, WEBSITE_LINK } from '../../constant/constants';
import { Interweave } from 'interweave';
import {
  FacebookIcon,
  FacebookMessengerIcon,
  FacebookMessengerShareButton,
  FacebookShareButton,
  WhatsappIcon,
  WhatsappShareButton,
} from 'react-share';

const MemberBlog = (props) => {
  const [data, setData] = useState([]);

  console.log('data', data);

  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const [totalPage, setTotalPage] = useState(1);
  const [expandedIndexes, setExpandedIndexes] = useState([]);
  const [lightboxOpen, setLightboxOpen] = useState(false);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [searchKey, setSearchKey] = useState('');
  const routeParams = useParams();
  const { blogId, id } = routeParams;

  console.log('blogId', blogId);

  console.log('searchKey', searchKey);

  const handleImageClick = (index) => {
    setCurrentImageIndex(index);
    setLightboxOpen(true);
  };

  const handleCloseLightbox = () => {
    setLightboxOpen(false);
  };
  const fetchData = useCallback(
    async (id) => {
      if (page <= totalPage || id) {
        try {
          const response = await axios.get(
            `${GET_BLOGS}?page=${page}&size=${50}&key=${searchKey}&id=${
              blogId || ''
            }&rl_no=${
              props?.rlNo || ''
            }`
          );
          const responseData = response.data;
          setTotalPage(responseData?.total_pages);
          const newData = responseData?.blogs;
          if (id) {
            setData(newData);
          } else {
            setData((prevData) => [...prevData, ...newData]);
          }

          if (page < responseData?.total_pages && !id) {
            setPage((prevPage) => prevPage + 1);
          } else {
            setHasMore(false);
          }
        } catch (error) {
          console.error('Error fetching data:', error);
        }
      }
    },
    [blogId, page, props.rlNo, searchKey, totalPage]
  );

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSeeMoreClick = (index) => {
    setExpandedIndexes((prevIndexes) => [...prevIndexes, index]);
  };

  return (
    <div>
      <div className='row '>
        <div className='col-lg-18 col-md-8 col-sm-12 mx-auto '>
          <input
            type='text'
            className='form-control bg-white p-3 mb-2'
            name='name'
            onChange={(e) => setSearchKey(e.target.value)}
            onKeyPress={(e) => {
              if (e.key === 'Enter') {
                fetchData(120); // Adjust the argument if needed
              }
            }}
            placeholder=' Search by Title'
            style={{ borderRadius: '20px' }}
          />
        </div>
      </div>
      <InfiniteScroll
        dataLength={data?.length}
        next={hasMore ? fetchData : null}
        hasMore={hasMore}>
        <div className='row mx-auto col-md-10 '>
          {data.map((blog, index) => (
            <div key={index} className='col-md-12 p-1 m-3'>
              <div className='p-4 rounded' style={{ border: '2px solid gray' }}>
                <div className='row'>
                  <div className='col-md-1'>
                    <img
                      src={`${BASE_URL}/${blog?.agency?.image}`}
                      alt=''
                      height='50px'
                      width='50px'
                      className='shadow-sm  mb-5 bg-body rounded-pill'
                    />
                  </div>
                  <div className='col-md-9'>
                    <div>
                      <h4>
                        {blog?.agency?.name}(RL-{blog?.agency?.rl_no})
                      </h4>
                    </div>
                    <div>
                      <small style={{ fontSize: '11px', textAlign: 'right' }}>
                        {moment(blog?.created_at).format('MMMM D [at] h:mm A')}
                      </small>
                    </div>
                  </div>
                  <div className='col-md-2 d-flex'>
                    {' '}
                    share:
                    <div
                      className='mx-auto d-flex'
                      style={{
                        fontSize: '14px',
                        justifyContent: 'space-around',
                      }}>
                      <div>
                        <FacebookShareButton
                          style={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            gap: '5px',
                          }}
                          url={`${WEBSITE_LINK}/blogs/${id}/${blog?.id}`}>
                          <FacebookIcon size={20} round />
                        </FacebookShareButton>
                      </div>

                      <div className='mx-2'>
                        <WhatsappShareButton
                          style={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            gap: '5px',
                          }}
                          url={`${WEBSITE_LINK}/blogs/${id}/${blog?.id}`}>
                          <WhatsappIcon size={20} round />
                        </WhatsappShareButton>
                      </div>

                      <div className=''>
                        <FacebookMessengerShareButton
                          style={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            gap: '5px',
                          }}
                          url={`${WEBSITE_LINK}/blogs/${id}/${blog?.id}`}
                          appId={'5515163185212209'}>
                          <FacebookMessengerIcon size={20} round />
                        </FacebookMessengerShareButton>
                      </div>
                    </div>
                  </div>
                </div>
                <div className='w-100'>
                  <Photogrid
                    images={blog?.images?.map(
                      (item) => `${BASE_URL}${item.image}`
                    )}
                    onClick={(index) => handleImageClick(index)}
                    width='100%' //optional according to your need
                    // maxWidth={400} //optional according to your need
                  >
                    {' '}
                    {lightboxOpen && (
                      <div
                        style={{
                          position: 'fixed',
                          top: 0,
                          left: 0,
                          width: '100%',
                          height: '100%',
                          background: 'rgba(0, 0, 0, 0.8)',
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                          zIndex: 999,
                        }}>
                        <img
                          src={`${BASE_URL}${blog?.images[currentImageIndex]?.image}`}
                          alt={`Image ${currentImageIndex + 1}`}
                          style={{
                            maxWidth: '90%',
                            maxHeight: '90%',
                            objectFit: 'contain',
                          }}
                        />
                        <button
                          onClick={handleCloseLightbox}
                          style={{
                            position: 'absolute',
                            top: '20px',
                            right: '20px',
                            background: 'none',
                            border: 'none',
                            color: '#fff',
                            fontSize: '20px',
                            cursor: 'pointer',
                          }}>
                          &#10005;
                        </button>
                      </div>
                    )}
                  </Photogrid>
                </div>
                <div>
                  <h3 className='mt-2'><b>{blog?.title}</b></h3>
                </div>
                <div>
                  <Interweave
                    allowAttributes
                    allowElements
                    disableLineBreaks={true}
                    content={
                      expandedIndexes.includes(index)
                        ? blog?.description
                        : `${blog?.description?.slice(0, 1000)}...`
                    }
                  />
                  {blog?.description?.length > 100 &&
                    !expandedIndexes.includes(index) && (
                      <span
                        style={{ color: 'blue', cursor: 'pointer' }}
                        onClick={() => handleSeeMoreClick(index)}>
                        See more
                      </span>
                    )}
                </div>
              </div>
            </div>
          ))}
        </div>
      </InfiniteScroll>
    </div>
  );
};

export default MemberBlog;
